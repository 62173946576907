import React from "react";

const PinIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width="18"
            height="18"
            fillRule="evenodd"
        >
            <g>
                <path
                    fill="#000000"
                    d="m24.721 19.84 3.498 3.499L3.038 45.023z"
                    opacity="1"
                    data-original="#1f3259"
                />
                <circle
                    cx="103.25"
                    cy="17.75"
                    r="7.25"
                    fill="#ED1B24"
                    transform="matrix(1.516 0 0 1.516 -122.655 -12.931)"
                    opacity="1"
                    data-original="#cd0000"
                />
                <circle
                    cx="103.25"
                    cy="17.75"
                    r="7.25"
                    fill="#ED1B24"
                    transform="matrix(1.516 0 0 1.516 -122.655 -12.931)"
                    opacity="1"
                    data-original="#cd0000"
                />
            </g>
        </svg>
    );
};

export default PinIcon;
