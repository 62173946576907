import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import Cookie from "../../../utils/cookie";
import HeaderNewLandingPage from "../HeaderNewLandingPage/HeaderNewLandingPage";
import {Alert, Button, Form, FormGroup} from "react-bootstrap";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FooterNewLandingPage from "../FooterNewLandingPage/FooterNewLandingPage";
import companyLogo from "./company-logo.svg";
import "./PartnershipPage.css";
import HeaderPartnerPage from "../HeaderNewLandingPage/HeaderPartnerPage";

const PartnershipPage = () => {
    const history = useHistory();
    const [username, setUsername] = useState(localStorage.getItem('email') !== null ? localStorage.getItem('email') : '');
    const [password, setPassword] = useState();
    const [loginError, setEmailError] = useState(false);
    const [errormessage, setErrorMessage] = useState("");
    const [sDeviceID, setCurrentIp] = useState(null);
    const [hidden, setHidden] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
    const inputRef = useRef();
    const isFormValid=username && password;
    useEffect(() => {
        getCurrentIp();
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setErrorMessage("Wrong username/password");
    }, []);

    const submitClickBtn = (e) => {
        e.preventDefault();
        const data = {
            email: username,
            password: password,
            sDeviceID,
            sDeviceType: "WEB",
        };
        axios.post(process.env.REACT_APP_SERVER + "auth/login", data, {
            headers: {"Content-Type": "application/json",},
        }).then((res) => {
            Cookie.save("isFirstTime","1");
            document.cookie = `serviceplanid=${
                res.data.users.serviceplanid
            }; expires=${new Date(Date.now() + 10800000).toUTCString()}; path=/`;
            localStorage.setItem("dashboardLayout", res.data.users.checkinbuttononly);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("login", true);
            localStorage.setItem("userid", res.data.users.id);
            localStorage.setItem("email", res.data.users.email);
            localStorage.setItem("name", res.data.users.fname + ' ' + res.data.users.lname);
            localStorage.setItem("isLogin", 1);
            // const user = res.data.users
            // if (user.newuser === 1 && user.twofactorauth === 1) {
            //     localStorage.setItem('isAuthenticatedOTP', '0');
            //     localStorage.setItem('dashboardLayout', 0);
            // } else if (user.newuser === 1) {
            //     localStorage.setItem('isBtnClicked', false)
            //     localStorage.setItem('isAuthenticatedOTP', '1');
            //     localStorage.setItem('dashboardLayout', 1);
            // } else {
            //     localStorage.setItem('isAuthenticatedOTP', res.data.users.twofactorauth === 0 ? '1' : '0');
            // }
            // localStorage.setItem('servicePlanAttempts', 0);
            // history.push("/maindashboard");
            setIsLoggedIn(true);
        }).catch(() => {
            setEmailError(true);
            setErrorMessage("Wrong Username/Password");
        });
    };

    const getCurrentIp = () => {
        axios.get("https://geolocation-db.com/json/")
            .then((res) => {
                setCurrentIp(res.data.IPv4);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            {/*<HeaderNewLandingPage/>*/}
            <HeaderPartnerPage/>
            <div className="inroduction_heading">

                <div className="new_heading text-center">
                    Your Company Name
                </div>

                <div className="d-flex justify-content-center mt-3">
                    <img src={companyLogo} alt="Company Logo"/>
                </div>
            </div>
            {!isLoggedIn ? (<>
                    <div className="container min-vh-100 d-flex align-items-center justify-content-center">
                        <div className="row w-100">
                            {/* Left Side - About This Service */}
                            <div className="col-md-5 d-flex align-items-center justify-content-end pe-md-4">
                                <div className="w-100">
                                    <h2 className="text-center font-weight-bold text-dark mb-3">About This Service</h2>
                                    <FormGroup>
                                        <Form.Control as="textarea" rows={5} placeholder="Enter details here..."
                                                      className="form-control"/>
                                    </FormGroup>
                                </div>
                            </div>

                            {/* Space Between */}
                            <div className="col-md-1 d-flex justify-content-center"></div>

                            {/* Right Side - Login Form */}
                            <div className="col-md-5 d-flex align-items-center ps-md-4">
                                <div className="signup_form login_form w-100">
                                    <div className="form_content">
                                        <h2 className="form_title text-center">Login</h2>
                                        <Form action="" onSubmit={submitClickBtn}>
                                            {loginError && (
                                                <Alert key="wrong-login" variant="danger">
                                                    {errormessage}
                                                </Alert>
                                            )}

                                            <FormGroup controlId="exampleForm.ControlInput3">
                                                <Form.Control
                                                    ref={inputRef}
                                                    type="email"
                                                    placeholder="Email Address"
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup controlId="exampleForm.ControlInput3"
                                                       className="position-relative">
                                                <Form.Control
                                                    placeholder="Password"
                                                    type={hidden ? "password" : "text"}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                                {hidden ? (
                                                    <VisibilityOffIcon className="pass_show"
                                                                       onClick={() => setHidden(!hidden)}/>
                                                ) : (
                                                    <VisibilityIcon className="pass_show"
                                                                    onClick={() => setHidden(!hidden)}/>
                                                )}
                                            </FormGroup>

                                            <p>
                                                <Link to={'/new-password'}>Forgot Password?</Link>
                                            </p>

                                            <Button
                                                variant="primary"
                                                disabled={!isFormValid}
                                                type="submit"
                                                className="btn-lg btn-block custom-btn"
                                            >
                                                <span>Login</span>
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            ) : (
                <div className="d-flex flex-column align-items-center py-3 pb-10 mt-4">
                    <Button variant="primary" size="sm" className="px-3 py-1 my-1">
                        <Link to="/agents" className="text-white text-decoration-none">Agents</Link>
                    </Button>

                    <Button variant="primary" size="sm" className="px-3 py-1 my-1">
                        <Link to="/clients" className="text-white text-decoration-none">Clients</Link>
                    </Button>
                    <Button variant="primary" size="sm" className="px-3 py-1 my-1">
                        Admin
                    </Button>
                </div>

            )}


            <FooterNewLandingPage/>
        </>
    );
};

export default PartnershipPage;