import React, {useState} from 'react';
import '../../PrivatePeople3LandingNew/Pages/Home/Header/header.css';
import './EstatePlanning/estimate-planning.css';
import keysLogoImage from '../../PrivatePeople3LandingNew/assest/images/logo.png';
import { Button, Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PinIcon from "./PinIcon";
import peaceOfMind from "../../../assets/images/homeWeb/peace-of-mind.png";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const Header = () => {
    const history = useHistory();
    const classes = useStyles();
    const [openPdfModel, setOpenPdfModel] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({email: ''});
    const anchor = 'right';
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const handlePdfPlan = () => {
        setErrors({email: ''});
        setEmail('');
        setOpenPdfModel(!openPdfModel);
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobile_menu">
                <div className='header_logo d-flex gap-2'>
                    <Link to='/'>
                        <img
                            src={keysLogoImage}
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Link>
                    <CloseIcon />
                </div>
                <Link to="/">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/home_webcopy" className="btn">Home</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/shop">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/shop_webcopy" className="btn">Shop</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/about">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/about_webcopy" className="btn">About</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/contact">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/webCopy/contact_webcopy" className="btn">Contact</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-signup">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-signup" className="btn Landing_signup_btn">Sign Up</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-login">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-login" className="btn Landing_login_btn">Login</ListItemText>
                    </ListItem>
                </Link>
            </List>
        </div>
    );

    return (
        <>
            <div className='introduction_section home_webcopy_section'>
                <Navbar className='main-header webcopy_main_header'>
                    <Navbar.Brand>
                        <Link to='/'>
                            <img
                                src={keysLogoImage}
                                className="d-inline-block align-top"
                                alt="logo"
                                style={{ width: "221px" }}
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse expand="md" className="justify-content-end">
                        <ul className='main_menu_list'>
                            <li className='menu_item active'>
                                <Link to="/">Home</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/shop">Shop</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/about">About</Link>
                            </li>
                            <li className='menu_item'>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                        <div className='header_menu_button'>
                            <Button variant="" className='header_menu_outlinebtn' onClick={() => history.push('/new-login')}>Login</Button>
                            <Button variant="" className='header_menu_btn ml-2' onClick={() => history.push('/new-signup')}>
                                Start Your Free 14-Day Trial
                            </Button>
                        </div>
                    </Navbar.Collapse>
                    <div className="menu_bar">
                        <MenuIcon onClick={toggleDrawer(anchor, true)} />
                    </div>
                </Navbar>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                </Drawer>
                <section className='home_section'>
                    <Container fluid>
                        <div className='inroduction_details'>
                            <Row className='align-items-center justify-content-center m-0'>
                                <Col xl={12}>
                                    <div className='inroduction_heading'>
                                        <h1 className="new_heading home_intro">
                                            Be Prepared <FavoriteIcon htmlColor={"#ED1B24"}/> <span style={{textWrap: "nowrap"}}>Be Remembered</span> <br/>
                                            Give Your Family Peace of Mind
                                        </h1>
                                        <img src={peaceOfMind} alt="peace of mind" width={250} className="peace_of_mind"/>
                                        <p className="home_intro_text">
                                            <b>Life is unpredictable. Whether it’s a wildfire, a pandemic, or the
                                                unexpected twists of life</b>,
                                            being prepared isn’t just about having documents in place—it’s about making
                                            sure your family
                                            <b> isn’t left searching, guessing, or overwhelmed</b> when the time comes.
                                        </p>
                                        <p className="home_intro_text">
                                            At KeysToMyWishes, we believe that planning for the future should be <b>simple,
                                            secure, and stress-free.</b>
                                            That’s why we’ve created a platform where you can <b>store, organize, and
                                            share your most important documents, memories,
                                            and final wishes</b>—so your loved ones will always have <b>clarity,
                                            guidance, and peace of mind</b> when they need it most.
                                        </p>
                                        <div className="ml-4">
                                            <p className="text-left home_intro_text text-dark">
                                                <div><PinIcon/> <b>For You:</b> Have everything in one place, ready for
                                                    life’s uncertainties.
                                                </div>
                                                <div><PinIcon/> <b>For Your Family:</b> No painful searching, no
                                                    difficult
                                                    decisions—just the knowledge of exactly what you wanted.
                                                </div>
                                                <div><PinIcon/> <b>For the Moments That Matter:</b> Choose the memories,
                                                    letters, and photos that define your legacy.
                                                </div>
                                            </p>
                                            <p className="text-left home_intro_text text-dark pt-2">
                                                <div><EmojiObjectsRoundedIcon htmlColor="#F7D308"/> <b>For
                                                    Parents:</b> Ensure that your
                                                    children don’t have to sort through <b>endless paperwork, emails,
                                                        and photos</b>—instead, leave them a <b>clear, organized
                                                        path</b> to honor your life the way you intended.
                                                </div>
                                                <div><EmojiObjectsRoundedIcon htmlColor="#F7D308"/> <b>For
                                                    Children:</b> Help your parents
                                                    prepare for the unexpected, so they can feel <b>secure knowing their
                                                        legacy is protected</b>—and you can focus on honoring, not
                                                    searching.
                                                </div>
                                            </p>
                                            <p className="text-left home_intro_text text-dark pt-2">
                                                <b>🔓 Your Privacy, Your Control</b>
                                                <p className="text-left home_intro_text text-dark ml-1 pt-1">
                                                    Your information stays completely private until the moment it’s needed.
                                                    You decide who gets access, when, and under what circumstances—ensuring
                                                    your personal details and final wishes remain protected until it’s time
                                                    to share them with those you trust.
                                                </p>
                                            </p>
                                        </div>
                                        <p className="text-left home_intro_text">
                                            This isn’t just about estate planning—it’s about <b>preserving the life
                                            you’ve
                                            built, the love you’ve shared, and the legacy you want to leave behind.</b>
                                        </p>
                                        <p className="text-left home_intro_text pt-1">
                                            <b><FavoriteIcon htmlColor={"#ED1B24"}/> Start today. Because peace of mind
                                                shouldn’t wait.</b>
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-xl-center justify-content-center  gap-2'>
                                        <div className='inroduction_details_btn mb-0'>
                                            <Button variant="" className='button_primary' onClick={() => history.push("/new-signup")}>
                                                Start Your Free 14-Day Trial
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    )
};
export default Header;