import React, {useEffect, useState} from 'react';
import {Button, Carousel, Col, Container, Modal, Row} from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import '../../NewLandingPage/NewLandingPage.css'
import Typography from "@material-ui/core/Typography";
import storageIcon from '../../../../src/assets/images/shopWeb/storage.svg';
import secureIcon from '../../../../src/assets/images/shopWeb/secure.svg';
import infoIcon from '../../../../src/assets/images/shopWeb/info.svg';
import uploadIcon from '../../../../src/assets/images/shopWeb/upload.svg';
import bookImg from '../../../../src/assets/images/shopWeb/book.png';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import './shop-webcopy.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { wish } from "../../../utils/wishes.utils";
import ktmwPdf from "../../../assets/images/KTMW-PURPLE.pdf";
import axios from "axios";
import blackImg from "../../../assets/images/black-book.png";
import pinkImg from "../../../assets/images/pink-book.png";
import purpleImg from "../../../assets/images/purple-book.png";
import whiteImg from "../../../assets/images/white-book.png";
import Image1 from "../../../assets/images/pdfModelHomePage/bannershortened1.png";
import Image2 from "../../../assets/images/pdfModelHomePage/ICE_2.png";
import Image3 from "../../../assets/images/pdfModelHomePage/bankaccounts_3.png";
import Image4 from "../../../assets/images/pdfModelHomePage/creditcards_4.png";
import Image5 from "../../../assets/images/pdfModelHomePage/myinfo_5.png";
import Image6 from "../../../assets/images/pdfModelHomePage/passwords_6.png";
import Image7 from "../../../assets/images/pdfModelHomePage/insurance_7.png";
import Image8 from "../../../assets/images/pdfModelHomePage/familyandfriends_8.png";
import Image9 from "../../../assets/images/pdfModelHomePage/memories_9.png";
import Image10 from "../../../assets/images/pdfModelHomePage/incapacitation_10.png";
import Image11 from "../../../assets/images/pdfModelHomePage/myfinalwishes_11.png";
import Image12 from "../../../assets/images/pdfModelHomePage/uponmydeath_12.png";
import Image13 from "../../../assets/images/pdfModelHomePage/backcover_13.png";


const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);


const AccordionSummary = withStyles({
    root: {
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const faqData1 = [
    {
        id: 1,
        question: "Can't I just use a Cloud Storage app to store my information?",
        answer: "You can, but then you won't be sure that your wishes will get to the right people. You'd also need to make sure that your loved ones know the password to your account, which would need to be stored somewhere. KeysToMyWishes has multiple options for you to store everything in one place."
    },
    {
        id: 2,
        question: "Can't I keep my information compiled in a safe place at home?",
        answer: "Absolutely. That's why we're happy to offer our Personal Affairs Organizer. Don't waste time guessing what your loved ones will need to settle your affairs. Information about power of attorney, your businesses, family heirlooms, and anything related to your last wishes-it's all included. You can purchase the organizer without purchasing digital storage with KeysToMyWishes."
    },
    {
        id: 3,
        question: "What kind of information can I store with KeysToMyWishes?",
        answer: "You can store anything on our platform, but we recommend that you store the important estate planning documents that your loved ones will need when you pass. This includes wills and trusts, attorney information, important passwords, real estate information, bank account numbers, stocks and bonds, and more. You can also store photos and videos on our digital platform that you may not want your loved ones to see until you're gone."
    },
    {
        id: 4,
        question: "Will KeysToMyWishes be able to see my private information?",
        answer: "No. With multi-factor authentication and 256-bit encryption, you're getting a secure log-in and the most up-to-date level of protection."
    }
];


const faqData2 = [

    {
        id: 5,
        question: "Can I leave something to a friend without a family member knowing?",
        answer: "Yes, you can leave whatever information you’d like to whoever you choose. The only people who will know what information you store are the predesignated loved ones you choose. They’ll get the information you stored upon your incapacitation or death."
    },
    {
        id: 6,
        question: "How will you know when to release my information?",
        answer: "We'll release your information when we receive word that you've become incapacitated or have passed away. This is why it's important to place the emergency cards you receive from us in places that are easy to find in your home."
    },
    {
        id: 7,
        question: "If someone finds me incapacitated or deceased, how will they know to contact KeysToMyWishes?",
        answer: "When you sign up with us, we'll provide you with 3 emergency cards. These cards have our contact information, so the person who finds you can contact us. You should place these cards in obvious locations in your home."
    }
];


const SafeStorageAndDelivery = () => {
    const [expandedId1, setExpandedId1] = React.useState('panel1');
    const [expandedId2, setExpandedId2] = React.useState('panel1');
    const history = useHistory();
    const [openPdfModel, setOpenPdfModel] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({email: ''});
    const [showModal, setShowModal] = useState(false);
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13];

    useEffect(() => {
        setExpandedId1(faqData1.length ? faqData1[0]['id'] : '')
        setExpandedId2(faqData2.length ? faqData2[0]['id'] : '')
    }, [faqData1, faqData2]);

    const handleChange = (id) => (event, newExpanded) => {
        setExpandedId1(newExpanded ? id : '');
        setExpandedId2(newExpanded ? id : '');
    };

    const validateForm = () => {
        if (!email.trim()) {
            setErrors({ email: 'Email address is required' });
            return false;
        }
        return true;
    };

    const handlePdfPlan = () => {
        setErrors({ email: '' });
        setEmail('');
        setOpenPdfModel(!openPdfModel);
    };

    const handlePdf = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                // Create download link for the PDF
                const link = document.createElement('a');
                link.href = ktmwPdf;
                link.download = `printable_personal_affairs_organizer.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Fetch the download link from the server
                const res = await axios.post(`${process.env.REACT_APP_SERVER}getDownLoadEmail`, {
                    sEmail: email,
                });
                if (res.data && res.data.downloadLink) {
                    window.location.href = res.data.downloadLink;
                }
            } catch (error) {
                console.error('Error fetching the PDF:', error);
                setErrors({ email: 'Failed to fetch PDF' });
            } finally {
                setOpenPdfModel(false);
            }
        }
    };

    return (
        <>
            <section className='section_container shop_web_introduction_section introduction_section'>
                <Container fluid>
                    <div className="text-center mb-3">
                        <button className="btn btn-danger" onClick={handlePdfPlan}>
                            <span className="mr-2">Download Our Free Printable PDF Version</span>
                        </button>
                    </div>
                    <Row className='align-items-center'>
                        <Col lg={7}>
                            <div className='header_section'>
                                <h3>
                                    KeysToMyWishes offers safe Storage and Delivery of your final wishes. Let go of
                                    worry and embrace the
                                    <span> peace of mind</span> that comes
                                    with securing your private information.
                                </h3>
                            </div>
                            <ul className='header_content_section'>
                                <li>
                                    <span>
                                        <CheckIcon/>
                                    </span>
                                    <h5 className="font-weight-normal mb-3">
                                        If something were to happen to you <strong>TODAY</strong> , <br/> would your
                                        loved ones know where to find your
                                        estate planning documents?
                                    </h5>
                                </li>
                                <li>
                                    <span>
                                        <CheckIcon/>
                                    </span>
                                    <h5 className="font-weight-normal mb-3">
                                        Will they have to sort through safes, cloud storages, hard drives, <br/> and
                                        mountains of
                                        paperwork
                                        in your home-adding <strong>STRESS</strong> to an already difficult time?
                                    </h5>
                                </li>
                                <li>
                                    <span>
                                        <CheckIcon/>
                                    </span>
                                    <h5 className="font-weight-normal mb-3">
                                        Are you <strong>CERTAIN</strong> that when it's your time, <br/> your last
                                        wishes will be conveyed to the right
                                        people?
                                    </h5>
                                </li>
                                <li>
                                    <span>
                                        <CheckIcon/>
                                    </span>
                                    <h5 className="font-weight-normal mb-3">
                                        Keep all your important information in one place with our Personal Affairs
                                        Organizer. <br/>
                                        Take control of what will happen when you’re gone with
                                        a <b>KeysToMyWishes</b> service plan.
                                    </h5>
                                </li>
                                <li>
                                    <span>
                                        <CheckIcon/>
                                    </span>
                                    <h5 className="font-weight-normal mb-3">
                                        We make things easy, so you can get back to living your best retired life.
                                    </h5>
                                </li>
                            </ul>
                        </Col>
                        <Col lg={5}>
                            <div className="col-md-12 text-center mt-5">
                                <Row className="justify-content-center">
                                    <section className="book_section">
                                        <Col md={12} className='book_main'>
                                            <p className='single_img'>
                                                <img src={blackImg} alt={"Organizer"} height={170} width={170}/>
                                                <p><strong>BLACK</strong></p>
                                            </p>
                                            <p className='single_img'>
                                                <img src={pinkImg} alt={"Organizer"} height={170} width={170}/>
                                                <p><strong>PINK</strong></p>
                                            </p>
                                            <p className='single_img'>
                                                <img src={purpleImg} alt={"Organizer"} height={170} width={170}/>
                                                <p><strong>PURPLE</strong></p>
                                            </p>
                                            <p className='single_img'>
                                                <img src={whiteImg} alt={"Organizer"} height={170} width={170}/>
                                                <p><strong>WHITE</strong></p>
                                            </p>
                                        </Col>
                                    </section>
                                </Row>
                                <Row className="mt-2 justify-content-center">
                                    <section className="custom_slider_btn text-center">
                                        <div className="d-flex flex-wrap justify-content-center mb-4">
                                            <button className="btn btn-primary m-2"
                                                    onClick={() => wish.newTab("https://www.amazon.com/dp/B0D7V33HFM?ref_=pe_93986420_774957520")}>
                                                Buy Book on Amazon
                                            </button>
                                            <button className="btn btn-primary m-2" onClick={handleShow}>
                                                View Organizer
                                            </button>
                                            <button className="btn btn-primary m-2"
                                                    onClick={() => history.push("/products")}>
                                                Buy Book/Binder Here
                                            </button>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary" onClick={handlePdfPlan}>
                                                <span className="mr-2">Download Our Free Printable PDF Version</span>
                                            </button>
                                        </div>
                                    </section>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section_container storage_options_section'>
                <Container fluid>
                    <Row>
                        <Col className="text-center">
                            <h2 className='main_title'>Storage + Organization Options</h2>
                        </Col>
                    </Row>
                    <Row className='justify-content-center custom_div'>
                        <div className="text-center custom_para_main">
                            Though our platform is highly secure, we understand you’re protective of your private
                            information. We’re proud to offer options that are tailored  to your level of comfort –
                            <b> so you’re in control of how your  information is organized and stored.</b>
                        </div>

                        <div className="custom_card">
                            <div className="single_card">
                                <div className="main_card_content">
                                    <h4 className="card_h4">I need help organizing
                                        my estate planning
                                        information.</h4>
                                    <div className="card_para">
                                        <p>Not sure what documents your loved ones will need to settle your affairs? We’ve got you covered.</p>
                                        <p>The KeysToMyWishes Personal Affairs Organizer is a comprehensive 36- page book with 25 estate templates that are easy to fill in. So you can feel at ease with a hard copy of your private information– organized and managed all together.</p>
                                        <p>Fill in information regarding your bank accounts, insurance, credit cards, passwords, last wishes, and more.</p>
                                        <table className="custom_card_tabel">
                                            <tbody>
                                            <tr>
                                                <td>Personal Affairs Organizer</td>
                                                <td>Print Book - $16.99</td>
                                                <td>
                                                    <div className="table_button">
                                                        <button
                                                            className="button_primary ml-2"
                                                            onClick={() => history.push('/products')}
                                                        > Buy Now
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Personal Affairs Organizer</td>
                                                <td>Binder - $26.99</td>
                                                <td>
                                                    <div className="table_button">
                                                        <button
                                                            className="button_primary ml-2"
                                                            onClick={() => history.push('/products')}
                                                        > Buy Now
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Personal Affairs Organizer</td>
                                                <td> Interactive <br/> PDF - $12.99</td>
                                                <td>
                                                    <div className="table_button">
                                                        <button
                                                            className="button_primary ml-2"
                                                            onClick={() => history.push('/products')}
                                                        > Buy Now
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {/*<tr>*/}
                                            {/*    <td>Personal Affairs Organizer</td>*/}
                                            {/*    <td>Printable <br/> PDF - Free </td>*/}
                                            {/*    <td>*/}
                                            {/*        <div className="table_button">*/}
                                            {/*            <button*/}
                                            {/*                className="button_primary ml-2 p-2"*/}
                                            {/*                onClick={handlePdfPlan}*/}
                                            {/*            > Free*/}
                                            {/*            </button>*/}
                                            {/*        </div>*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="single_card pink_content">
                                <div className="main_card_content">
                                    <h4 className="card_h4">I need somewhere to
                                        store my estate planning
                                        information.</h4>
                                    <div className="card_para">
                                        <p>Ready for secure, safe digital storage of your personal information? Upgrade
                                            your estate planning with our service plan – a yearly subscription of the
                                            KeysToMyWishes digital storage app. Upload your private information to our
                                            platform and we’ll release your last wishes to your loved ones after you’ve
                                            passed.
                                        </p>
                                        <p>In addition you can save information such as photos and videos to share and
                                            leave to loved ones.
                                        </p>
                                        <table className="custom_card_tabel">
                                            <tbody>
                                                <tr>
                                                    <td>Yearly KTMW Service Plan</td>
                                                    <td>$60.00</td>
                                                    <td>
                                                        <div className="table_button">
                                                            <button
                                                                className="button_primary ml-2"
                                                                onClick={() => history.push('/products')}
                                                            > Buy Now
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="single_card">
                                <div className="main_card_content">
                                    <h4 className="card_h4">I need help organizing
                                        and storing my estate
                                        planning information.</h4>
                                    <div className="card_para">
                                        <p>Still hesitant about keeping your information safe online? With our service
                                            plan, store only the location of your Personal Affairs Organizer on our
                                            platform. Or store the password to your device, so your loved ones can
                                            access your Personal Affairs Organizer PDF. We’ll tell your loved ones the
                                            information they need when it’s time.
                                        </p>
                                        <p>In addition you can save information such as photos and videos to share and
                                            leave to loved ones.
                                        </p>
                                        <table className="custom_card_tabel">
                                            <tbody>
                                                <tr>
                                                    <td>Personal Affairs Organizer Print Book + Yearly KTMW Service Plan</td>
                                                    <td>$76.99</td>
                                                    <td>
                                                        <div className="table_button litte_wide">
                                                            <button
                                                                className="button_primary ml-2"
                                                                onClick={() => history.push('/new-signup')}
                                                            > Start Your Free 14-Day Trial
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Personal Affairs Organizer Binder + Yearly KTMW Service Plan</td>
                                                    <td>$86.99</td>
                                                    <td>
                                                        <div className="table_button litte_wide">
                                                            <button
                                                                className="button_primary ml-2"
                                                                onClick={() => history.push('/new-signup')}
                                                            > Start Your Free 14-Day Trial
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Personal Affairs Organizer PDF + Yearly KTMW Service Plan</td>
                                                    <td>$72.99</td>
                                                    <td>
                                                        <div className="table_button litte_wide">
                                                            <button
                                                                className="button_primary ml-2"
                                                                onClick={() => history.push('/new-signup')}
                                                            > Start Your Free 14-Day Trial
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className='section_container process_section_container'>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h2 className='main_title'>A Simple 3-Step Process for Releasing Your Last Wishes</h2>
                            <p className='sub_title mt-2'>
                                If you choose a service plan with us, we’ll release your last wishes to your loved ones
                                upon your incapacitation or death.
                            </p>
                        </Col>
                    </Row>

                    <Row className='process_steps_list'>

                        <Col sm={2}>
                            <div className='process_steps text-center'>
                                <h2>01</h2>
                            </div>
                        </Col>
                        <Col sm={10}>
                            <div className='process_steps_content'>
                                <p>
                                    Choose the loved ones who will receive your private information and last wishes.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='process_steps_list'>

                        <Col sm={2}>
                            <div className='process_steps text-center'>
                                <h2>02</h2>
                            </div>
                        </Col>
                        <Col sm={10}>
                            <div className='process_steps_content'>
                                <p>
                                    We’ll send you 3 emergency cards with our contact information. Place the cards
                                    around your house.
                                    If someone finds you incapacitated or deceased, they can contact <span
                                    className='strong_title'>KeysToMyWishes</span>.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='process_steps_list'>

                        <Col sm={2}>
                            <div className='process_steps text-center'>
                                <h2>03</h2>
                            </div>
                        </Col>
                        <Col sm={10}>
                            <div className='process_steps_content'>
                                <p>
                                    We’ll release your information and last wishes to your designated loved ones upon
                                    confirmation of your incapacitation or death.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section_container storagelist_section_container'>
                <Container fluid>
                    <Row>
                        <Col className="text-center">
                            <h2 className='main_title'>KeysToMyWishes Digital Storage</h2>
                        </Col>
                    </Row>
                    <Row className='storage_box_list'>
                        <Col sm={6} xl={3} className='mb-3'>
                            <div className='storage_box'>
                                <span className='storage_box_icon'>
                                    <img src={storageIcon} alt='storageIcon' className='img-fluid' />
                                </span>
                                <div>

                                    <p>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        2 gigabytes of storage-plenty of space for everything you want to store in one place.</p>

                                    <p className='mb-0'>

                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Mobile app with fingerprint identification. No more passwords to remember.
                                    </p>
                                </div>
                            </div>

                        </Col>
                        <Col sm={6} xl={3} className='mb-3'>
                            <div className='storage_box'>
                                <span className='storage_box_icon'>
                                    <img src={secureIcon} alt='secureIcon' className='img-fluid' />
                                </span>
                                <div>

                                    <p>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Multi-factor authentication for a secure log in.</p>

                                    <p className='mb-0'>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Break-The-Glass functionality which will trigger the release of your information to your designated loved ones.
                                    </p>
                                </div>
                            </div>

                        </Col>
                        <Col sm={6} xl={3} className='mb-3'>
                            <div className='storage_box'>
                                <span className='storage_box_icon'>
                                    <img src={infoIcon} alt='infoIcon' className='img-fluid' />
                                </span>
                                <div>

                                    <p>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        256-bit encryption for the latest level of protection of your important information.</p>

                                    <p className='mb-0'>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Mobile app with fingerprint identification. No more passwords to remember
                                    </p>
                                </div>
                            </div>

                        </Col>
                        <Col sm={6} xl={3} className='mb-3'>
                            <div className='storage_box'>
                                <span className='storage_box_icon'>
                                    <img src={uploadIcon} alt='uploadIcon' className='img-fluid' />
                                </span>
                                <div>

                                    <p>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Easy document and file upload for your convenience.
                                    </p>

                                    <p className='mb-0'>
                                        <span>
                                            <CircleIcon />
                                        </span>
                                        Break-The-Glass functionality which will trigger the release of your information to your
                                        designated loved ones.
                                    </p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section_container asked_questions_section'>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h2 className='main_title'>Frequently Asked Questions</h2>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col lg={6}>
                            <div className="faq" id="faq-accordion">
                                {faqData1.map((data) => (
                                    <Accordion square expanded={expandedId1 === data.id} onChange={handleChange(data.id)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                            <Typography className={`accordion-button mb-2 d-flex justify-content-between ${expandedId1 === data.id ? '' : 'collapsed'}`}>
                                                {data.question}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="mx-3">{data.answer}</AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="faq" id="faq-accordion">
                                {faqData2.map((data) => (
                                    <Accordion square expanded={expandedId2 === data.id} onChange={handleChange(data.id)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                            <Typography className={`accordion-button mb-2 d-flex justify-content-between ${expandedId2 === data.id ? '' : 'collapsed'}`}>
                                                {data.question}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="mx-3">{data.answer}</AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section_container trial_section'>
                <Container fluid>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg={8}>
                            <p>Don't wait until it's <strong>too late.</strong> Take the weight off your shoulders when it comes to your
                                future planning. Secure your last wishes  and have <strong>peace of mind</strong> now.</p>
                        </Col>
                        <Col lg={4}>
                            <button className="button_primary" onClick={() => history.push("/new-signup")}>Start Your 14-day Trial today</button>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal show={openPdfModel} onHide={handlePdfPlan} className='activeModel'>
                <Modal.Header
                    closeButton
                    style={{backgroundColor: "#0069d9", color: "white"}}
                >
                    <Modal.Title>Free Printable PDF Download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This is our Printable PDF version of our Personal Affairs Organizer.
                        You will be able to print this and keep for your personal use.</p>
                    <p> With your Email address we will inform you of any new pages and any page updates.</p>
                    <p>Please check out our Interactive version which does cost. The Digital Interactive PDF version allows
                        you to enter your important information allowing you to digitally store your important information on your computer or your smartphone.</p>
                    <form onSubmit={handlePdf}>
                        <div className="mb-3">
                            <label className='font-weight-bold'>Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>
                        <Modal.Footer>
                            <Button type="submit" className="btngenie">
                                Submit
                            </Button>
                            <Button className="btngenie" onClick={handlePdfPlan}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>


            <Modal show={showModal} onHide={handleClose}>
                <style>
                    {`
        .modal-centered {
            display: flex;
            align-items: center;
            justify-content: center;  
            padding:0;
        }
        .modal-dialog {
            max-width: 700px;
            margin: auto;
        }
        .carousel-item img {
            max-height: 830px;
        }
        .carousel-control {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            background: rgba(255, 255, 255, 0.7);
            border: none;
            border-radius: 50%;
            width: 40px; 
            height: 40px; 
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .carousel-control-prev {
            left: 10px;
        }
        .carousel-control-next {
            right: 10px;
        }
        .fa-chevron-left:before {
            color: black;
            font-size: 25px;
        }
        .fa-chevron-right:before {
            color: black;
            font-size: 25px;
        }
        `}
                </style>
                <Modal.Header closeButton>
                    <Modal.Title>Example Pages of our Organizer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false}>
                        {images.map((image, idx) => (
                            <Carousel.Item key={idx}>
                                <img
                                    className="d-block w-100"
                                    src={image}
                                    alt={`Slide_${idx + 1}`}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer className="modal-centered">
                    <button className="carousel-control carousel-control-prev" onClick={() => handleSelect(index > 0 ? index - 1 : images.length - 1)}>
            <span aria-hidden="true">
                <i className="fas fa-chevron-left"></i>
            </span>
                    </button>
                    <button className="carousel-control carousel-control-next" onClick={() => handleSelect((index + 1) % images.length)}>
            <span aria-hidden="true">
                <i className="fas fa-chevron-right"></i>
            </span>
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SafeStorageAndDelivery;