import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip, Alert,
} from "react-bootstrap";
import "./clients.css";

import genie_img from "../../assets/images/genie_image.jpg";
import axios from "axios";
import ReactModal from "react-modal-resizable-draggable";
import Close from "../../assets/images/close.png";
import Star_icon from "../../assets/images/start_icon.png";
import shareLinkIcon from "../../assets/images/LandingPage/shareIcon.png";
import inviteIcon from "../../assets/images/LandingPage/invitationIcon.png";
import sharedWishesIcon from "../../assets/images/ic_users.png";
import releaseWishesIcon from "../../assets/images/Release.png";
import Invitemodel from "./InviteModel";
import {makeStyles} from "@material-ui/core/styles";
import HandleConfirmation from "../handleConfirmation";
import PhoneNumberField from "../../components/PhoneNumber";
import {isValidPhoneNumber} from "../../utils/phone";

const useStyles = makeStyles(() => ({
  sharedWishesModalHeader: {
    background: '#007bff',
    color: 'white',
  },
}));

const NewContact = ({
  handleCloseNewmodel,
  newContectModel,
  submitContactClickBtn,
  checkboxFun,
  orderContact,
  setOrderContact,
  isNewContact,
  fName,
  setfName,
  lName,
  setlName,
  sEmail,
  setsEmail,
  value,
  setValue,
  nisICE,
  handleinvitationModel,
  contactDetails,
  contactId,
  sendShow,
  setSendShow,
  nUserID,
  GetContactData,
  sendInviteData,
                      contactdata
}) => {
  const inputRef = useRef(null);
  const classes = useStyles();
  const [getstartData, setgetstartData] = React.useState("");
  const [showLegend, setShowLegend] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [resData, setResData] = useState();
  const [nWhichOne, setNWhichOne] = React.useState("");
  const [sharableURL, setSharableURL] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(false);

  const [isOpenShareLinkInfoModal, setShareLinkInfoModal] = React.useState(false);

  useEffect(() => {
    if(newContectModel) {
      inputRef.current.focus();
    }
     setPhoneError(false);
  }, [newContectModel]);

  useEffect(() => {
    if(contactdata.length ===0 && newContectModel ){
      setShowLegend(true);
      handleletstart();
    }
  }, [newContectModel]);

  const handleLegendDailog = () => {
    setShowLegend(true);
    handleletstart();
  };

  const handleCloseLegend = () => {
    setShowLegend(false);
  };

  const handleCloseModel = () => {
    setShowModel(false);
  };

  const handleletstart = () => {
    axios
      .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=36`)
      .then((res) => {
        setgetstartData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShowModal = (nWhichOne) => {
    setShowModel(true);
    setNWhichOne(nWhichOne);
    axios
      .get(
        process.env.REACT_APP_SERVER +
          `inventory/getsharedwithdistributedto?nUserID=${localStorage.getItem(
            "userid"
          )}&nContactID=${contactId}&nWhichOne=${nWhichOne}`,
        {
          headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        }
      )
      .then((res) => {
        setResData(res.data);
      });
  };

  const sendTextEmaildata = () => {
    setSendShow(false);
  };

  const closeModal = () => {
    setSendShow(false);
  };

  let inviteData = {
    fname: fName,
    lname: lName,
    contactid: contactId,
    email: sEmail,
    phonenumber: contactDetails.phonenumber,
  };

  const handleShowShareLinkModal = () => {
    var token = localStorage.getItem("token");
    let data = {
      nUserID:  nUserID,
      nContactID: contactId
    }

    if (contactDetails["hasSharedWithRecords"]){
      axios
          .post(process.env.REACT_APP_SERVER + "contact/getcontacturl", data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setSharableURL(res.data[0]._URL);
            setSendShow(true);
          })
          .catch((err) => {
            console.log(err);
          });
    } else {
      setShareLinkInfoModal(true);
    }
  };

  const handleSaveContact = (e) => {
    e.preventDefault();
    if(isValidPhoneNumber(value)) {
      submitContactClickBtn(e);
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
  }

  return (
    <>
      <Modal
          show={showModel}
          onHide={handleCloseModel}
          size={"xl"}
          centered
          className={"contactGenieModalContainer shared-wishes-modal"}
      >
        <Modal.Header closeButton className={classes.sharedWishesModalHeader}>
          <Modal.Title>
            {nWhichOne === 1 ? (
                <h5>Wishes that will be Shared With</h5>
            ) : (
                <h5>Wishes that will be Released To</h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resData?.length < 1 ? (
              <p className={"text-center"}>No Data Found!</p>
          ) : (
              <>
                <div className="distributed-Instructions">
                  {nWhichOne === 1 ? (
                      <>
                        <span>
                          The following is a List of Wishes that will be Shared
                          With: {fName + " " + lName}
                        </span>
                        <p>
                          This link will allow your contact: {fName + " " + lName}{" "}
                          to view your Wishes that you are sharing.{" "}
                        </p>
                      </>
                  ) : (
                      <p>
                        The following is a List of Wishes that will be Released to:{" "}
                        {fName + " " + lName}
                      </p>
                  )}
                </div>
                {resData?.map((e, index) => {
                  return (
                      <div key={index}>
                        <div
                            style={{ marginTop: "10px" }}
                            className="underline-text-all"
                            dangerouslySetInnerHTML={{
                              __html: e?.itemname.replaceAll("\n", "<br/>"),
                            }}
                        />
                        {resData?.length > 1 && <hr />}
                      </div>
                  );
                })}
              </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
              variant="secondary"
              onClick={handleCloseModel}
              style={{ backgroundColor: "#7030a0" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {sendShow ? (
          <Invitemodel
              sendShow={sendShow}
              setValue={setValue}
              sendTextEmaildata={sendTextEmaildata}
              GetContactData={GetContactData}
              closeModal={closeModal}
              sendInviteData={inviteData}
              nUserID={nUserID}
              nContactID={contactId}
              sharableURL={sharableURL}
          />
      ) : null}

      <Modal
        show={newContectModel}
        onHide={handleCloseNewmodel}
        centered
        size="lg"
        className="contact_person_model"
      >
        <Modal.Header
          closeButton
          className="ShareRelease-Modal-Header justify-content-center"
          style={{}}
        >
          <Modal.Title>Contact Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={12}>
            <Form
              onSubmit={handleSaveContact}
              className="ml-4 New-Contact-form-data"
            >
              <Form.Group>
                <Row>
                  <div className="genieReverse">
                    <img
                      src={genie_img}
                      alt="genie_img"
                      className="genie"
                      onClick={() => handleLegendDailog()}
                    />
                  </div>
                </Row>
                {phoneError && (
                    <Alert key="wrong-login" variant="danger">
                      The phone number does not appear to be valid!
                    </Alert>
                )}
                <Row>
                  <div
                    className="checkbox-flex justify-content-between d-flex mt-3"
                    style={{ width: "98%", minHeight: "40px" }}
                  >
                    <div className="d-flex align-items-center checkbox-contact">
                      <input
                        type="checkbox"
                        checked={nisICE == 1}
                        onChange={(e) => checkboxFun(e)}
                        className="Checked-box mr-2 emer_check_box"
                      />
                      <label className="lable-case">Emergency Contact</label>
                    </div>
                    {nisICE == 1 && (
                      <div className="d-flex justify-content-end align-items-center contact-order-person w-50">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              The order in which you want us to contact your
                              contact person(s).
                            </Tooltip>
                          }
                        >
                          <Button className="btn-contact-lable">
                            <label>Order of Contact:</label>
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              The order in which you want us to contact your
                              contact person(s).
                            </Tooltip>
                          }
                        >
                          <Button className="btn-order-contact">
                            <Form.Control
                              type="number"
                              value={orderContact > 0 ? orderContact : ""}
                              onChange={(e) => setOrderContact(e.target.value)}
                            />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                </Row>
              </Form.Group>
              <div className="clearfix"></div>
              <Form.Group
                className="float-none clearfix"
              >
                <Row>
                  <Col md={3} className="d-flex  align-items-center">
                    <label className="pt-2">First Name:</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      onChange={(e) => setfName(e.target.value)}
                      value={fName}
                      required
                      ref={inputRef}
                      maxLength="50"
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md={3} className="d-flex  align-items-center">
                    <label className="pt-2">Last Name:</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      onChange={(e) => setlName(e.target.value)}
                      value={lName}
                      maxLength="50"
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md={3} className="d-flex  align-items-center">
                    <label className="pt-2">Phone:</label>
                  </Col>
                  <Col md={9}>
                    <PhoneNumberField
                        setPhoneNumber={(event) => {
                          setValue(event)
                        }}
                        phoneNumber={value}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md={3} className="d-flex  align-items-center">
                    <label className="pt-2">Email :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="email"
                      onChange={(e) => setsEmail(e.target.value)}
                      value={sEmail}
                      maxLength="100"
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-0"
              >
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "fit-content" }}
                  className="btn-lg btn-block custom-btn btn-hover mx-auto"
                >Save
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Modal.Body>
        {/*{!isNewContact && (*/}
        {/*    <Modal.Footer>*/}
        {/*      <Col className={"contact_person_footer"}>*/}
        {/*    <span className="btn_group justify-content-between w-100">*/}
        {/*      {!isNewContact && (*/}
        {/*          <Button*/}
        {/*              variant="primary"*/}
        {/*              onClick={() => handleShowModal(1)}*/}
        {/*              className="btn-lg btn-block custom-btn btn-hover mx-auto"*/}
        {/*          >*/}
        {/*            <img src={sharedWishesIcon} alt={"inviteIcon"} /> &nbsp;Shared Wishes*/}
        {/*          </Button>*/}
        {/*      )}*/}
        {/*      {!isNewContact && (*/}
        {/*          <Button*/}
        {/*              variant="primary"*/}
        {/*              onClick={() => handleShowModal(2)}*/}
        {/*              className="btn-lg btn-block custom-btn btn-hover mx-auto"*/}
        {/*          >*/}
        {/*            <img src={releaseWishesIcon} alt={"inviteIcon"} /> &nbsp;Release Wishes*/}
        {/*          </Button>*/}
        {/*      )}*/}
        {/*      {!isNewContact && (*/}
        {/*          <Button*/}
        {/*              variant="primary"*/}
        {/*              onClick={() => handleShowShareLinkModal()}*/}
        {/*              className="btn-lg btn-block custom-btn btn-hover mx-auto"*/}
        {/*          >*/}
        {/*            <img src={shareLinkIcon} alt={"inviteIcon"} /> &nbsp;Share Link*/}
        {/*          </Button>*/}
        {/*      )}*/}
        {/*    </span>*/}
        {/*        /!*{!isNewContact && (*!/*/}
        {/*        /!*    <div className={"share-container"}>*!/*/}
        {/*        /!*      <Button*!/*/}
        {/*        /!*          className={"mr-1 custom-btn invite-button"}*!/*/}
        {/*        /!*          onClick={() => handleinvitationModel(contactDetails)}*!/*/}
        {/*        /!*      >*!/*/}
        {/*        /!*        <img src={inviteIcon} alt={"inviteIcon"} /> &nbsp;Send Invite*!/*/}
        {/*        /!*      </Button>*!/*/}
        {/*        /!*    </div>*!/*/}
        {/*        /!*)}*!/*/}
        {/*      </Col>*/}
        {/*    </Modal.Footer>*/}
        {/*)}*/}
      </Modal>

      <ReactModal
        initWidth={400}
        initHeight={500}
        top={50}
        left={0}
        onFocus={() => {}}
        className={"my-modal-custom-class"}
        onRequestClose={handleCloseLegend}
        minHeight={500}
        minWidth={400}
        isOpen={showLegend}
      >
        <h4 className="header_Contact">
          <div className="pr-2 start_icon">
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`} style={{ zIndex: "999999999999" }}>
                  You can move this screen anywhere by clicking in the title bar
                </Tooltip>
              }
            >
              <img src={Star_icon} alt="Star_icon" height={25} width={25} />
            </OverlayTrigger>
          </div>
          <div dangerouslySetInnerHTML={{ __html: getstartData.theheader }} />
          <div className="pr-2 close_icon" onClick={handleCloseLegend}>
            <img src={Close} alt="Close" height={20} width={20} />
          </div>
        </h4>
        <div className="body">
          <div
            className={"ml-3 pt-5 dataMain"}
            dangerouslySetInnerHTML={{ __html: getstartData.thetext }}
          />
        </div>
      </ReactModal>
      <HandleConfirmation
          isOpen={isOpenShareLinkInfoModal}
          handleClose={() => setShareLinkInfoModal(false)}
          onConfirm={()=>setShareLinkInfoModal(false)}
          question="Sorry, you don't have any wishes shared with this contact. <br/>You must assign the contact to at least one Wish in order to use this Shared Link button."
          yesText="Okay"
          noText=""
      />
    </>
  );
};

export default NewContact;
