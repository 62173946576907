import React from "react";
import {Redirect, Route, Switch,} from "react-router-dom";
import {withRouter} from "react-router";
import Footer from './components/footer/footer';
import Gamerules from './screens/gamerules/gamerules';
import Howitworks from './screens/howitworks/howitworks';
import Privaypolicy from './screens/privacypolicy/privacypolicy';
import Support from './screens/support';
import Marketing from './screens/marketing';
import Termsofuse from './screens/termsofuse/termsofuse';
import Dashboard from './screens/dashboard/dashboard';
import AddNewItem from './screens/addNewItem/addNewItem';
import Maindashboard from './screens/maindashboard/maindashboard';
import Contacts from './screens/contacts/contacts';
import Myprofile from './screens/myprofile/myprofile';
import Userhistory from './screens/userhistory/userhistory';
import Billing from './screens/billing/billing';
import FAQ from './screens/FAQ/FAQ';
import Changepassword from './screens/ChangePassword/Changepassword';
import LandingPage from './screens/LandingPage/LandingPage';
import Login from './screens/NewLandingPage/Login/Login';

import NewLogin from './screens/LandingPageNew/LoginPage';
import NewSignup from './screens/LandingPageNew/SignupPage';

import NewDashboard from './screens/newDashboard/dashboard';
import NewAddNewItem from './screens/newAddNewItem/addNewItem';
import NewPassword from './components/ForgetPassword/NewPassword'
import IntermediatePage from "./components/InterMediatedPage/IntermediatePage";
import FileListing from "./components/FileListing/FileListing";
import LandingPageNew from "./screens/LandingPageNew/LandingPageNew"

import Landing from "./screens/Landing"
import Home from "./screens/PrivatePeopleLandingNew/Pages/Home"
import HomePage from "./screens/PrivatePeople3LandingNew/Pages/Home"
import About from "./screens/PrivatePeopleLandingNew/Pages/About"
import AboutPage from "./screens/PrivatePeople3LandingNew/Pages/About"
import Features from "./screens/PrivatePeopleLandingNew/Pages/Features"
import Pricing from "./screens/PrivatePeopleLandingNew/Pages/Pricing"
import PricingPage from "./screens/PrivatePeople3LandingNew/Pages/Pricing"
import Contact from "./screens/PrivatePeopleLandingNew/Pages/Contact"
import ContactPage from "./screens/PrivatePeople3LandingNew/Pages/Contact"
import Cart from './screens/PrivatePeople3LandingNew/Pages/Cart'

// admin
import CheckInPage from './screens/CheckInPages/CheckInPage';
import TimeSlot from "./screens/TimeSlot/TimeSlot";
import CheckInResponse from './screens/CheckInPages/CheckInResponse'
import WishesSharedWithMe from "./screens/WishesSharedWithMe/WishesSharedWithMe";
import NewInvitedUser from "./screens/NewInvitedUser/NewInvitedUser";
import ViewNewsharedWish from "./screens/NewInvitedUser/ViewNewsharedWish";
import WishesReleasedWithMe from "./screens/WishesReleasedWithMe/WishesReleasedWithMe";
import TwoFactorPrivateRoute from "./PrivateRoute/twoFactorPrivateRoute";
import PrivateRoute from "./PrivateRoute/privateRoute";
import ViewSharedWish from "./screens/WishesSharedWithMe/ViewSharedWish";
import ViewReleasedWish from "./screens/WishesReleasedWithMe/ViewReleasedWish";
import MyServicePlanSelection from "./screens/MyServicePlan/MyServicePlanSelection";
import FirstPlanSelection from "./screens/MyServicePlan/FirstPlanSelection";
import ChooseServicePlan from "./screens/MyServicePlan/ChooseServicePlan";
import OtpVerification from './screens/OTPVerification/OTPVerification'
import HowitsWork from "./screens/HowitsWork/HowitsWork";
import HandleChangePassword from './components/ForgetPassword/HandleChangePassword';
import HelpMe from "./screens/NewLandingPage/landingpages/HelpMe";
import Confidential from "./screens/NewLandingPage/landingpages/Confidential";
import Matters from "./screens/NewLandingPage/landingpages/Matters";
import EasySteps from "./screens/NewLandingPage/landingpages/EasySteps"
import KeepInTouch from "./screens/NewLandingPage/landingpages/KeepInTouch";
import MyAlbums from "./screens/MyAlbums/MyAlbums";
import AlbumsImages from "./screens/MyAlbums/AlbumsImages";
import ImageDownload from "./screens/MyAlbums/ImageDownload";
import InviteForm from "./screens/contacts/InviteForm";
import ViewMessage from "./screens/MyAlbums/ViewMessage";
import AboutUs from "./screens/NewLandingPage/landingpages/AboutUs";
import KeysToWishes from "./screens/NewLandingPage/landingpages/KeysToWishes";
import SubscribePlan from './admin/screen/SubscribePlan/SubscribePlan';
import AccountPauseActivated from './admin/screen/Account/AccountPauseActivated';
import KeepInTouchCheckIn from "./screens/NewLandingPage/landingpages/keepInTouchCheckIn";
import BreakTheGlass from "./screens/NewLandingPage/landingpages/break-the-glass";
import KTMWPitchDeck from "./screens/KTMWPitchDeck";
import KTMWFamilyPitchDeck from "./screens/KTMWFamilyPitchDeck";
import StripeElement from "./components/StripeElement";

import 'react-phone-input-2/lib/style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HowToScreen from "./screens/HowToScreen";
import {LinkedInCallback} from "react-linkedin-login-oauth2";
import SocialLandingPage from "./screens/LandingPageNew/SocialLandingPage";
import NewHomePage from "./screens/LandingPageNew/NewHomePage";
import NewPlanSection from "./screens/LandingPageNew/helpingSection/NewPlanSection";
import NewStandardfeatures from "./components/standardfeatures/NewStandardfeatures";
import NewContactSection from "./screens/LandingPageNew/helpingSection/NewContactSection";
import LandingNewuneexpacted from "./screens/Landing-newuneexpacted";
import PersonalAffairsPage from "./screens/PersonalAffairsPage";
import OrganizerLandingPage from "./screens/PrivatePeople3LandingNew/Pages/Organizer";
import NewLookPricing from "./screens/PrivatePeople3LandingNew/Pages/NewPricing";
import HomeWebCopy from "./screens/webCopy/HomeWebCopy";
import ShopWebCopy from "./screens/webCopy/ShopWebCopy/ShopWebCopy";
import AboutWebCopyPage from "./screens/webCopy/AboutWebCopy/AboutPage";
import ContactWebCopy from "./screens/webCopy/ContactWebCopy";
import HomeWebBook from "./screens/webBook/HomeWebBook";
import ShopWebBook from "./screens/webBook/ShopWebBook/ShopWebCopy";
import AboutWebBookPage from "./screens/webBook/AboutWebBook/AboutPage";
import ContactWebBook from "./screens/webBook/ContactWebBook";
import ContextProvider from "./screens/PrivatePeople3LandingNew/Pages/NewPricing/PricingList/App";
import ProductRecipt from "./components/ProductRecipt";
import PartnershipPage from "./screens/LandingPageNew/PartnershipPage";
import Agents from "./screens/agents/agents";
import Clients from "./screens/clients/clients";
const App = () => {
    return (
        <ContextProvider>
            <main>
                <Switch>
                    {/* privatepeople3 */}

                    <Route path="/" exact component={HomeWebCopy}/>
                    <Route path="/product_recipt" exact component={ProductRecipt}/>
                    {/*<Route path="/" exact component={HomePage}/>*/}
                    <Route path="/book" exact component={HomeWebBook}/>
                    <Route path="/agents" exact component={Agents}/>
                    <Route path="/clients" exact component={Clients}/>
                    <Route path="/shop_book" exact component={ShopWebBook}/>
                    <Route path="/about_book" exact component={AboutWebBookPage}/>
                    <Route path="/contact_book" exact component={ContactWebBook}/>
                    <Route path="/home_webcopy" exact component={HomeWebCopy}/>
                    <Route path="/shop" exact component={ShopWebCopy}/>
                    <Route path="/about" exact component={AboutWebCopyPage}/>
                    <Route path="/contact" exact component={ContactWebCopy}/>
                    <Route path="/organizer" exact component={OrganizerLandingPage}/>
                    {/*<Route path="/about" exact component={AboutPage}/>*/}
                    <Route path="/about" exact component={AboutWebCopyPage}/>
                    <Route path="/pricing" exact component={PricingPage}/>
                    <Route path="/products" exact component={NewLookPricing}/>
                    {/*<Route path="/contact" exact component={ContactPage}/>*/}
                    <Route path="/personal-affairs-info" exact component={PersonalAffairsPage}/>

                    <Route path="/old-home" exact component={LandingPageNew}/>
                    <Route path="/newHome" exact component={NewHomePage}/>
                    <Route path="/privatepeople" exact component={Landing}/>
                    <Route path="/theunexpected" exact component={LandingNewuneexpacted}/>
                    <Route path="/privatepeople2" exact component={Home}/>
                    <Route path="/privatepeople2/about" exact component={About}/>
                    <Route path="/privatepeople2/features" exact component={Features}/>
                    <Route path="/privatepeople2/pricing" exact component={Pricing}/>
                    <Route path="/privatepeople2/contact" exact component={Contact}/>
                   {/* <Route path="/myprivacy" exact component={MyPrivacyPage}/>*/}

                    <Route path="/new-login" exact component={NewLogin}/>
                    <Route path="/partner-portal" exact component={PartnershipPage}/>
                    <Route exact path="/linkedin" component={LinkedInCallback} />
                    <Route path="/new-signup" exact component={NewSignup}/>
                    <Route path="/New-Wishes-Shared-me/:EncodedData" exact component={NewInvitedUser}/>
                    <Route path="/View-Wishes/:EncodedData" exact component={ViewNewsharedWish}/>
                    <Route path="/" exact component={LandingPage}/>
                    <Route path="/OtpVerification/:EncodedData" exact component={OtpVerification}/>
                    <Route path="/addNewItem/:id" exact component={AddNewItem}/>
                    <Route path="/how" exact component={Howitworks}/>
                    <Route path="/privacy-policy" exact component={Privaypolicy}/>
                    <Route path="/terms-of-use" exact component={Termsofuse}/>
                    <Route path="/how-its-work" exact component={HowitsWork}/>
                    <Route path="/faq" exact component={FAQ}/>
                    <Route path="/landing-page" exact component={LandingPage}/>
                    <Route path="/fileListing" exact component={FileListing}/>
                    <Route path="/new-password" exact component={NewPassword}/>
                    <Route path="/update-new-password" exact component={HandleChangePassword}/>
                    <Route path="/login" exact component={Login}/>
                    {/*<Route path="/signup" exact component={Signup}/>*/}
                    <Route path="/Intermediate-Page" exact component={IntermediatePage}/>
                    <Route path="/how-it-can-help-me" exact component={HelpMe}/>
                    <Route path="/Confidential" exact component={Confidential}/>
                    <Route path="/keep-in-touch" exact component={KeepInTouchCheckIn}/>
                    <Route path="/break-the-glass" exact component={BreakTheGlass}/>
                    <Route path="/why-it-matters" exact component={Matters}/>
                    <Route path="/easy-steps" exact component={EasySteps}/>
                    <Route path="/keep-in-touch" exact component={KeepInTouch}/>
                    <Route path="/About-Us" exact component={AboutUs}/>
                    <Route path="/Keys-To-My-Wishes" exact component={KeysToWishes}/>

                    <Route path="/ktmw-pitch-deck" exact component={KTMWPitchDeck}/>
                    <Route path="/familyandfriendsinvestmentgroup" exact component={KTMWFamilyPitchDeck}/>

                    <Route path="/invite-signup/:encoded/:email" exact component={InviteForm}/>

                    <Route path="/support" exact component={ContactWebCopy}/>
                    <Route path="/marketing" exact component={Marketing}/>
                    <Route path="/how-to" exact component={HowToScreen}/>
                    <Route path="/social" exact component={SocialLandingPage}/>
                    <Route path="/pricing" exact component={NewPlanSection}/>
                    <Route
                        path="/features"
                        exact
                        render={(props) => <NewStandardfeatures {...props} box={true} />}
                    />
                    <Route path="/contact" exact component={NewContactSection}/>

                    <Route
                        path="/changePlan"
                        exact
                        component={StripeElement}
                    />
                    <Route path ="/cart" exact component={Cart}/>

                    <PrivateRoute path="/maindashboard" exact component={Maindashboard}/>
                    <PrivateRoute path="/CheckInPage" exact component={CheckInPage}/>
                    <PrivateRoute path="/CheckInResponse" exact component={CheckInResponse}/>
                    <PrivateRoute path="/View-Shared-Wish/:id" exact component={ViewSharedWish}/>
                    <PrivateRoute path="/View-Released-Wish/:id" exact component={ViewReleasedWish}/>
                    <PrivateRoute path="/billing" exact component={Billing}/>
                    <PrivateRoute path="/userhistory" exact component={Userhistory}/>
                    <PrivateRoute path="/dashboard" exact component={Dashboard}/>
                    <PrivateRoute path="/dashboard/:id" exact component={Dashboard}/>
                    <PrivateRoute path="/addNewItem" exact component={AddNewItem}/>
                    <PrivateRoute path="/game-rules" exact component={Gamerules}/>


                    <TwoFactorPrivateRoute path="/TimeSlot" exact component={TimeSlot}/>
                    <TwoFactorPrivateRoute path="/MyServicePlanSelection" exact component={MyServicePlanSelection}/>
                    <TwoFactorPrivateRoute path="/chooseServicePlan" exact component={ChooseServicePlan}/>
                    <TwoFactorPrivateRoute path="/chooseServicePlan/:id" exact component={ChooseServicePlan}/>
                    <TwoFactorPrivateRoute path="/SelectYourPlan" exact component={FirstPlanSelection}/>
                    <TwoFactorPrivateRoute path="/newDashboard" exact component={NewDashboard}/>
                    <TwoFactorPrivateRoute path="/newDashboard/:id" exact component={NewDashboard}/>
                    <TwoFactorPrivateRoute path="/newAddNewItem" exact component={NewAddNewItem}/>
                    <TwoFactorPrivateRoute path="/newAddNewItem/:id" exact component={NewAddNewItem}/>
                    <TwoFactorPrivateRoute exact path="/account" component={AccountPauseActivated}/>
                    <TwoFactorPrivateRoute exact path="/subscribe-plan" component={SubscribePlan}/>
                    <TwoFactorPrivateRoute path="/Wishes-Shared-with-me" exact component={WishesSharedWithMe}/>
                    <TwoFactorPrivateRoute path="/Wishes-Released-to-me" exact component={WishesReleasedWithMe}/>
                    <TwoFactorPrivateRoute path="/contacts" exact component={Contacts}/>
                    <TwoFactorPrivateRoute path="/my-profile" exact component={Myprofile}/>
                    <TwoFactorPrivateRoute path="/change-password" exact component={Changepassword}/>
                    <TwoFactorPrivateRoute path="/my-albums" exact component={MyAlbums}/>
                    <TwoFactorPrivateRoute path="/albums-images" exact component={AlbumsImages}/>
                    <TwoFactorPrivateRoute path="/albums-images/:id" exact component={AlbumsImages}/>
                    <TwoFactorPrivateRoute path="/image-download" exact component={ImageDownload}/>
                    <TwoFactorPrivateRoute path="/image-download/:id/:mediaid/:s3fileName/:index" exact component={ImageDownload}/>
                    <TwoFactorPrivateRoute path="/view-massage/:message" exact component={ViewMessage}/>
                    <Redirect to={'/'} />
                </Switch>
                <Footer/>
            </main>
        </ContextProvider>
    );
}

export default withRouter(App);
