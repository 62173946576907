import React, {useState} from 'react';
import KTMWOrganizer from './KTMW-Organizer.png';
import secureImg from '../../../../assets/images/homeWeb/secure.png';
import secureImg1 from '../../../../assets/images/homeWeb/secure1.png';
import './estimate-planning.css';
import '../../common.css';
import {Button, Col, Container, Carousel, Modal, Row} from 'react-bootstrap';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BookIcon from '@mui/icons-material/Book';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {Card} from 'react-bootstrap';
import dotsImage from "../../../Landing/assets/images/dot.svg";
import linesImage from "../../../Landing/assets/images/lines.svg";
import WishesVideo from "../../../../assets/images/KeysToMyWishes.mp4";
import {wish} from "../../../../utils/wishes.utils";
import blackImg from '../../../../assets/images/black-book.png';
import pinkImg from '../../../../../src/assets/images/pink-book.png';
import purpleImg from '../../../../../src/assets/images/purple-book.png';
import whiteImg from '../../../../../src/assets/images/white-book.png';
import pdfImg from '../../../../../src/assets/images/pdf_img.png';
// import Slider from 'react-slick';
import {useHistory} from "react-router-dom";
import ktmwPdf from "../../../../assets/images/KTMW-PURPLE.pdf";
import axios from "axios";
import Image1 from '../../../../assets/images/pdfModelHomePage/bannershortened1.png'
import Image2 from '../../../../assets/images/pdfModelHomePage/ICE_2.png'
import Image3 from '../../../../assets/images/pdfModelHomePage/bankaccounts_3.png'
import Image4 from '../../../../assets/images/pdfModelHomePage/creditcards_4.png'
import Image5 from '../../../../assets/images/pdfModelHomePage/myinfo_5.png'
import Image6 from '../../../../assets/images/pdfModelHomePage/passwords_6.png'
import Image7 from '../../../../assets/images/pdfModelHomePage/insurance_7.png'
import Image8 from '../../../../assets/images/pdfModelHomePage/familyandfriends_8.png'
import Image9 from '../../../../assets/images/pdfModelHomePage/memories_9.png'
import Image10 from '../../../../assets/images/pdfModelHomePage/incapacitation_10.png'
import Image11 from '../../../../assets/images/pdfModelHomePage/myfinalwishes_11.png'
import Image12 from '../../../../assets/images/pdfModelHomePage/uponmydeath_12.png'
import Image13 from '../../../../assets/images/pdfModelHomePage/backcover_13.png'
import peaceOfMind from "../../../../assets/images/homeWeb/peace-of-mind.png";


const EstatePlanning = () => {
    const history = useHistory();
    const [openPdfModel, setOpenPdfModel] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({email: ''});
    const [index, setIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13];

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        autoplay: false,
        autoplaySpeed: 1500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
        ]
    };
    const validateForm = () => {
        if (!email.trim()) {
            setErrors({email: 'Email address is required'});
            return false;
        }
        return true;
    };
    const handlePdfPlan = () => {
        setErrors({email: ''});
        setEmail('');
        setOpenPdfModel(!openPdfModel);
    };
    const handlePdf = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                // Create download link for the PDF
                const link = document.createElement('a');
                link.href = ktmwPdf;
                link.download = `printable_personal_affairs_organizer.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Fetch the download link from the server
                const res = await axios.post(`${process.env.REACT_APP_SERVER}getDownLoadEmail`, {
                    sEmail: email,
                });
                if (res.data && res.data.downloadLink) {
                    window.location.href = res.data.downloadLink;
                }
            } catch (error) {
                console.error('Error fetching the PDF:', error);
                setErrors({email: 'Failed to fetch PDF'});
            } finally {
                setOpenPdfModel(false); // Close the modal after handling
            }
        }
    };
    return (
        <>
            <Container fluid className="p-0">
                <section className='section_container home_webcopy_section pb-3'>
                    <Container>
                        <Row>
                            <Col lg={12} className='p-0 side_box_img'>
                                <Card className='helper_video_container'>
                                    <Card.Body className='p-0'>
                                        <div className='helper_video_header'>
                                            <div className='d-flex justify-content-between'>
                                                <img
                                                    src={dotsImage}
                                                    className="d-inline-block align-top"
                                                    alt="images"
                                                />
                                                <img
                                                    src={linesImage}
                                                    className="d-inline-block align-top"
                                                    alt="images"
                                                />
                                            </div>
                                        </div>
                                        <div className='helper_video_frame'>
                                            <div className='helper_video'>
                                                <video width="560" height="503" controls>
                                                    <source src={WishesVideo} type="video/mp4"/>
                                                </video>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12} className='side_box_section text-center'>
                                <div className='text-center'>
                                    <h2 className='main_title'>Estate Planning Support</h2>
                                    <p className='sub_title'>
                                        <span className='strong_title'>KeysToMyWishes</span> provides <b>all-in-one
                                        print
                                        and digital storage solutions</b> for your private
                                        information and <b>release of your last wishes</b>. So you can rest assured
                                        knowing
                                        your loved ones will be prepared to settle your affairs.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='section_container secure_section'>
                    <Container>
                        <Row className='align-items-center'>
                        <Col lg={6}>
                                <div className=''>
                                    <h2 className='main_title'>Let us paint a picture for you.</h2>
                                    <p className='sub_title'>
                                        Your loved ones receive word that you’ve passed. They’re now tasked with
                                        settling
                                        your affairs.
                                        They spend countless hours:
                                    </p>
                                </div>
                                <div>
                                    <ul className='for_you_list'>
                                        <li>
                                            <span><DocumentScannerIcon/></span>
                                            Looking for the key and password to <br/> your safe
                                        </li>
                                        <li>
                                            <span><InsertDriveFileIcon/></span>
                                            Digging through mountains of paperwork <br/> for important documents
                                        </li>
                                        <li>
                                            <span><BookIcon/></span>Flipping through notebooks in search <br/> of
                                            passwords
                                        </li>

                                        <li>
                                            <span><VerifiedUserIcon/></span>
                                            Sifting through files on hard drives, not <br/> sure what to make of any of
                                            it
                                        </li>
                                    </ul>

                                </div>
                            </Col>
                            <Col lg={6}>
                                <img src={secureImg} alt='planningImg' className='img-fluid'/>
                            </Col>
                        </Row>
                        <Row className='align-items-center'>

                            <Col lg={6} className='for_you_content_img mt-4'>
                                <img src={secureImg1} alt='planningImg' className='img-fluid'/>
                            </Col>

                            <Col lg={6} className='mt-4'>
                                <div className='for_you_content'>
                                    <p>
                                        You can’t control what happens after you pass, but you don’t want to
                                        add unnecessary
                                        frustration to the process. You want your loved ones to easily manage your
                                        affairs.
                                    </p>
                                    <p>What if you had <strong>one safe place</strong> to securely store your private
                                        end-of-life
                                        information?</p>
                                    <p>A place that releases your information to your pre-designated loved ones upon
                                        your
                                        incapacitation
                                        or death.</p>
                                    <p><span className='strong_title'>KeysToMyWishes</span> is ready to help you
                                        eliminate
                                        stress for your loved ones. <br/>
                                        You deserve to enjoy your retirement with <strong>peace of mind</strong>.</p>
                                </div>
                            </Col>
                        </Row>
                        <div className='text-center'>
                            <button onClick={() => {
                                history.push('/new-signup')
                            }} className='button_primary_outline'>
                                Secure Your Information Now
                            </button>
                        </div>
                    </Container>
                </section>
                <section className='align-items-center justify-content-center m-0 home_webcopy_section'>
                    <Col xl={12} className="home_section">
                        <div className='inroduction_heading'>
                            <div>
                                <button className="btn btn-danger printable-pdf" onClick={handlePdfPlan}>
                                    <span className="mr-2">Download Our Free Printable PDF Version</span>
                                </button>
                            </div>
                            <h1 className="new_heading">Digital Storage Solution</h1>
                            <h6>
                                Take control of how your end-of-life information is stored.<br/>
                                Keep your private information safe with <br/>
                                <span> Ironclad digital protection  and release of your last wishes.</span>
                            </h6>
                        </div>
                        <div className='d-flex justify-content-xl-center justify-content-center  gap-2'>
                            <div className='inroduction_details_btn mb-0'>
                                <Button variant="" className='button_primary' onClick={() => history.push("/new-signup")}>
                                    Start Your Free 14-Day Trial
                                </Button>
                            </div>
                        </div>
                    </Col>
                </section>
                <section>
                    <Container className="home_webcopy_section ">
                        <div className="p-5 text-center col-lg-12">
                            <div className="text-center inroduction_heading">
                                <h2 className="new_heading">Book/Binder Storage Solution</h2>
                                <p className="sub_title">
                                    Though our platform is highly secure, we understand you’re protective of your
                                    private information. We’re proud to offer options that are tailored to your level of
                                    comfort – so you’re in control of how your information is organized and stored.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <img src={KTMWOrganizer} alt="KTMW Organizer" width="100%" style={{maxWidth: "800px"}}/>
                        </div>
                    </Container>
                </section>
                <section className='section_container home_webcopy_section storage_section_container'>
                    <Container>
                        <Row className="justify-content-center">
                            <div className='text-center'>
                                <h2 className='main_title'>Organization Support</h2>
                                <p className='sub_title mb-5'>
                                    The KeysToMyWishes Personal Affairs Organizer is a comprehensive book that allows
                                    you to
                                    keep your end-of-life information in one place. <span
                                    onClick={() => wish.newTab("https://www.amazon.com/dp/B0D7V33HFM?ref_=pe_93986420_774957520")}>As seen on Amazon.</span>
                                </p>
                            </div>
                        </Row>
                        {/*<Row>*/}
                        {/*    <section className="custom_slider d-flex justify-content-center mt-5">*/}
                        {/*        <Col md={9}>*/}
                        {/*            <Slider {...settings}>*/}
                        {/*                <Col md={12} className="d-flex justify-content-center">*/}
                        {/*                    <img src={blackImg} alt={"Organizer"} height={200} width={200}/>*/}
                        {/*                </Col>*/}
                        {/*                <Col md={12} className="d-flex justify-content-center">*/}
                        {/*                    <img src={pinkImg} alt={"Organizer"} height={200} width={200}/>*/}
                        {/*                </Col>*/}
                        {/*                <Col md={12} className="d-flex justify-content-center">*/}
                        {/*                    <img src={purpleImg} alt={"Organizer"} height={200} width={200}/>*/}
                        {/*                </Col>*/}
                        {/*                <Col md={12} className="d-flex justify-content-center">*/}
                        {/*                    <img src={whiteImg} alt={"Organizer"} height={200} width={200}/>*/}
                        {/*                </Col>*/}
                        {/*                <Col md={12} className="d-flex justify-content-center">*/}
                        {/*                    <img src={pinkImg} alt={"Organizer"} height={200} width={200}/>*/}
                        {/*                </Col>*/}
                        {/*            </Slider>*/}
                        {/*        </Col>*/}
                        {/*    </section>*/}
                        {/*</Row>*/}
                        <Row className="justify-content-center">
                            <section className="book_section">
                                <Col md={9} className='book_main'>
                                    <p className='single_img'>
                                        <img src={blackImg} alt={"Organizer"} height={200} width={200}/>
                                    </p>
                                    <p className='single_img'>
                                        <img src={pinkImg} alt={"Organizer"} height={200} width={200}/>
                                    </p>
                                    <p className='single_img'>
                                        <img src={purpleImg} alt={"Organizer"} height={200} width={200}/>
                                    </p>
                                    <p className='single_img'>
                                        <img src={whiteImg} alt={"Organizer"} height={200} width={200}/>
                                    </p>
                                </Col>
                            </section>
                        </Row>
                        <Row className="mt-2 justify-content-center">
                            <section className="custom_slider_btn text-center">
                                <div className="d-flex flex-wrap justify-content-center mb-4">
                                    <button className="btn btn-primary m-2"
                                            onClick={() => wish.newTab("https://www.amazon.com/dp/B0D7V33HFM?ref_=pe_93986420_774957520")}>
                                        Buy Book on Amazon
                                    </button>
                                    <button className="btn btn-primary m-2" onClick={handleShow}>
                                        View Organizer
                                    </button>
                                    <button className="btn btn-primary m-2" onClick={() => history.push("/products")}>
                                        Buy Book/Binder Here
                                    </button>
                                </div>
                            </section>
                        </Row>
                        <Row>
                            <section className="w-100">
                                <div className="home_webcopy_section">
                                    <div className="p-5 text-center col-lg-12">
                                        <div className="text-center inroduction_heading">
                                            <h2 className="new_heading">Interactive PDF Storage Solution</h2>
                                            <p className="sub_title">The digital Interactive PDF version of My Personal
                                                Affairs Organizer allows you to enter your information onto your
                                                computer or
                                                your smart phone devices storing your information locally.
                                                You can keep this information saved on your device.
                                                You can also print this information out into book form from your device
                                                whenever you decide to.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="pb-5 text-center">
                                        <img src={pdfImg} alt="Pdf" width="100%" style={{maxWidth: "250px"}}/>
                                        <div
                                            className="d-flex pt-4 justify-content-xl-center justify-content-center  gap-2">
                                            <div className="inroduction_details_btn mb-0">
                                                <button onClick={() => history.push("/products")} type="button"
                                                        className="button_primary btn">Buy Interactive PDF Version
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Row>
                        <Row>
                            <Col xl={4}>
                                <Card className='support_card'>
                                    <Card.Body>
                                        <h4>Storage Peace of Mind</h4>
                                        <div className='mt-4'>
                                            <p>
                                                Chuck the hesitation about uploading your private information online.
                                            </p>
                                            <p>
                                                Have a hard copy of your private information on hand, whenever you need
                                                it.
                                            </p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={4}>
                                <Card className='support_card'>
                                    <Card.Body>
                                        <h4>Organizational Bliss</h4>
                                        <div className='mt-4'>
                                            <p>
                                                Keep everything your loved ones will need after you pass in one place.
                                            </p>
                                            <p>
                                                With 25 fillable templates, know exactly what documents you need to have
                                                in
                                                order for your loved ones to settle your affairs.
                                            </p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={4}>
                                <Card className='support_card'>
                                    <Card.Body>
                                        <h4>Retirement Relaxation</h4>
                                        <div className='mt-4'>
                                            <p>
                                                Feel accomplished that you’ve done what you can to prepare your loved
                                                ones
                                                for your passing.
                                            </p>
                                            <p>
                                                Enjoy your retirement doing what you want instead of worrying about what
                                                will happen after you pass.
                                            </p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='section_container support_section_container'>
                    <Container>
                        <div className='text-center'>
                            <h2 className='main_title'>Storage + Organization Support</h2>
                            <p className='sub_title mb-5'>
                                Upload your personal information to our highly secure platform. Select the loved one
                                you’d
                                like
                                your information to go to after you’ve become incapacitated or have passed. We’ll handle
                                the
                                rest.
                            </p>
                        </div>
                        <Row>
                            <Col xl={4}>
                                <Card className='support_card'>
                                    <Card.Body>
                                        <h4>Online Safety</h4>
                                        <div className='mt-4'>
                                            <p>
                                                Optional two-factor authentication for a safe log-in.
                                            </p>
                                            <p>
                                                256-bit encryption for airtight digital storage. <span
                                                className='strong_title'>KeysToMyWishes</span> won’t be able to see your
                                                private information.
                                            </p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={4}>
                                <Card className='support_card danger_card'>
                                    <Card.Body>
                                        <h4>Ease of Use</h4>
                                        <div className='mt-4'>
                                            <p>
                                                A user-friendly app with simple upload of your private information.
                                            </p>
                                            <p>
                                                Biometric scan log-in. Say goodbye to remembering another password.
                                            </p>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={4}>
                                <Card className='support_card'>
                                    <Card.Body>
                                        <h4>Comfort</h4>
                                        <div className='mt-4'>
                                            <p>
                                                2GB of storage space for all your important files and private
                                                information.
                                            </p>
                                            <p>
                                                Choice in how you want to store your information.
                                                Store the location of your KTMW Personal Affairs Organizer with us,
                                                or upload your private information directly to our encrypted platform.
                                            </p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className=' quote_section'>
                    <Container fluid className='quote_box_container'>
                        <div className='quote_box_content text-center'>
                            <div className='avtar-img'>
                            <span>
                                GA
                            </span>
                            </div>
                            <div className='mt-4'>
                                <h4>
                                    ...I am single and somewhat of a private person. Especially as it pertains to my
                                    financial
                                    affairs. I love the fact that I can simply make an entry into Keys with the location
                                    of
                                    my
                                    important documents without having to give this information to someone.
                                </h4>
                            </div>
                            <h6>-Greg A., KeysToMyWishes user</h6>
                            <FormatQuoteIcon className='quote_icon'/>
                        </div>
                    </Container>
                </section>
            </Container>
            <Modal show={openPdfModel} onHide={handlePdfPlan} className='activeModel'>
                <Modal.Header
                    closeButton
                    style={{backgroundColor: "#0069d9", color: "white"}}
                >
                    <Modal.Title>Free Printable PDF Download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This is our Printable PDF version of our Personal Affairs Organizer.
                        You will be able to print this and keep for your personal use.</p>
                    <p> With your Email address we will inform you of any new pages and any page updates.</p>
                    <p>Please check out our Interactive version which does cost. The Digital Interactive PDF version
                        allows
                        you to enter your important information allowing you to digitally store your important
                        information on your computer or your smartphone.</p>
                    <form onSubmit={handlePdf}>
                        <div className="mb-3">
                            <label className='font-weight-bold'>Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>
                        <Modal.Footer>
                            <Button type="submit" className="btngenie">
                                Submit
                            </Button>
                            <Button className="btngenie" onClick={handlePdfPlan}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleClose}>
                <style>
                    {`
        .modal-centered {
            display: flex;
            align-items: center;
            justify-content: center;  
            padding:0;
        }
        .modal-dialog {
            max-width: 700px;
            margin: auto;
        }
        .carousel-item img {
            max-height: 830px;
        }
        .carousel-control {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            background: rgba(255, 255, 255, 0.7);
            border: none;
            border-radius: 50%;
            width: 40px; 
            height: 40px; 
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .carousel-control-prev {
            left: 10px;
        }
        .carousel-control-next {
            right: 10px;
        }
        .fa-chevron-left:before {
            color: black;
            font-size: 25px;
        }
        .fa-chevron-right:before {
            color: black;
            font-size: 25px;
        }
        `}
                </style>
                <Modal.Header closeButton>
                    <Modal.Title>Example Pages of our Organizer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false}>
                        {images.map((image, idx) => (
                            <Carousel.Item key={idx}>
                                <img
                                    className="d-block w-100"
                                    src={image}
                                    alt={`Slide_${idx + 1}`}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer className="modal-centered">
                    <button className="carousel-control carousel-control-prev" onClick={() => handleSelect(index > 0 ? index - 1 : images.length - 1)}>
            <span aria-hidden="true">
                <i className="fas fa-chevron-left"></i>
            </span>
                    </button>
                    <button className="carousel-control carousel-control-next" onClick={() => handleSelect((index + 1) % images.length)}>
            <span aria-hidden="true">
                <i className="fas fa-chevron-right"></i>
            </span>
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default EstatePlanning;