import React from "react";
import './HeaderNewLandingPage.css';
import keysNewLogo from "../../PrivatePeople3LandingNew/assest/images/logo.png";
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import clsx from 'clsx';
import {Link} from "react-router-dom";
import companyLogo from "../PartnershipPage/company-logo.svg";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const HeaderNewPartnerPage = () => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const currentPath = window.location.pathname;
    const hiddenPaths = "/partner-portal";
    const isHidden = hiddenPaths.includes(currentPath);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobile_menu">
                <Link to="/new-signup" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-signup" className="btn btn-primary btn Landing_signup_btn">Sign Up</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/new-login" className="text-center">
                    <ListItem button key={'text'} className="flex-wrap">
                        <ListItemText to="/new-login" className="btn Landing_login_btn">Login</ListItemText>
                    </ListItem>
                </Link>
            </List>
        </div>
    );


    const anchor = 'right';
    return (
        <div className='main_header'>
            {isHidden ? (
                <Link to="/" className="flex font-bold text-black flex-col items-center">
                    <img src={companyLogo} className="" alt="Company Logo" />
                    <div className="pl-3  text-lg font-bold text-gray-900">Your Logo</div>
                </Link>
            ) : (
                <Link to="/">
                    <img src={keysNewLogo} className="logo_img" alt="Keys New Logo" />

                </Link>
            )}
            {!isHidden && (<div className="menu_list">
                <Link to="/new-signup" className={`btn ${window.location.pathname === '/new-signup' ? 'btn-primary' : 'Landing_login_btn'} mr-4`}>Sign Up</Link>
                <Link to="/new-login" className={`btn ${window.location.pathname === '/new-login' ? 'btn-primary' : 'Landing_login_btn'}`}>Log In</Link>
            </div>)}
            {!isHidden &&(<div className="menu_bar">
                <MenuIcon onClick={toggleDrawer(anchor, true)}/>
            </div>)}
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
            </Drawer>
        </div>
    )
}
export default HeaderNewPartnerPage;