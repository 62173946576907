import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Modal } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import "./clients.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Header from "../../components/NewHeader/Header";
import Logout from "../../components/logout/Logout";
import genie_img from "../../assets/images/genie_image.jpg";
import Back from "../../assets/images/Back_white.png";
import SendTextEmailModal from "../../components/newNotesform/SendTextEmailModal";
import SessionExpired from "../../components/SessionExpired/SessionExpired";
import NewContact from "./NewContact";
import ReleaseImg from "../../assets/images/Release.png"
import {
    Box,
    Card,
    CardContent, Checkbox,
    Grid,
    Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Menutiles from "../../assets/images/menutile.png";
import Menutiles2 from "../../assets/images/menu-tile-2.png";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import GenieMen from "./GenieMen.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import ic_users from "../../assets/images/ic_users.png";
import Typography from "@material-ui/core/Typography";
import SharedAccessApprovalModal from "../../components/SharedAccessApprovalModal";
import DraggebleModal from "../../components/DraggebleModal";
import HandleConfirmation from "../handleConfirmation";
import {phoneNumber} from "../../utils/phone";
import shareImg from "../../assets/images/shareicon.svg"
import Invitemodel from "./InviteModel";
import {wish} from "../../utils/wishes.utils";

const useStyles = makeStyles(() => ({
    sharedWishesModalHeader: {
        background: '#007bff',
        color: 'white',
    },
}));
const Contacts = () => {
    const classes = useStyles();
    const nUserID = localStorage.getItem("userid");
    const [contactdata, setContactdata] = useState([]);
    const [isLoadedContactData, setIsLoadedContactData] = useState(false);
    const [nContactID, setnContactID] = useState(0);
    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    const [sAddress, setsAddress] = useState();
    const [sCity, setsCity] = useState();
    const [nStateID, setnStateID] = useState();
    const [sZipCode, setsZipCode] = useState();
    const [sEmail, setsEmail] = useState();
    const [sPhoneNumber, setsPhoneNumber] = useState();
    const [sharewith, setsharewith] = useState();
    const [releaseto, setreleaseto] = useState();
    const [nisICE, setNisICE] = useState(0);
    const [sNotes, setsNotes] = useState();
    const [edit, setEdit] = useState(false);
    const [deletebtnDisable, setDeletebtnDisable] = useState(false);
    const [orderContact, setOrderContact] = useState("");
    const [value, setValue] = useState("");
    const [deletMsg, setDeletMsg] = useState("");
    const [contactDetails, setcontactData] = React.useState("");
    const [isContactSaved, setContactSavedModal] = React.useState(false);

    const [showLegend, setShowLegend] = useState(false);
    const [TokenExpired, setTokenExpired] = useState(false);

    const [tile, settile] = useState();
    const [alignment, setAlignment] = React.useState("");

    const [nWhichOne, setNWhichOne] = React.useState("");
    const [InvitaionModel, setInvitaionModel] = React.useState(false);

    const [invitedFname, setinvitedFname] = React.useState("");
    const [invitedLname, setinvitedLname] = React.useState("");
    const [mailUser, setMailUser] = useState("");
    const [inviteContactId, setInviteContactId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [noDataContent, setNoDataContent] = useState([]);
    const [ServicePlan, setServicePlan] = useState(null);
    const [isOpenSharedModal, setOpenSharedModal] = useState(false);
    const [isSaved, setisSaved] = useState(false);

    const toggleApprovalModal = () => {
        setOpenSharedModal(!isOpenSharedModal);
    }

    const handleinvitationModel = (contact) => {
        if (contact) {
            setInviteContactId(contact.contactid);
            setinvitedFname(contact.fname);
            setMailUser(contact.email);
            setinvitedLname(contact.lname);
        }
        setInvitaionModel(!InvitaionModel);
    };
    const handleinvitationModelClose = () => {
        setInvitaionModel(false);
    };

    const getServicePlan = () => {
        var token = localStorage.getItem('token');
        const nUserID = localStorage.getItem('userid');
        const data = {nUserID}
        axios.post(process.env.REACT_APP_SERVER + `user/getuserserviceplan`, data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((res) => {
            if (res.data.status) {

            } else {
                if (res.data[0] == undefined || null) {
                } else {
                    setServicePlan(res.data[0])
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const sentInvitaionemail = () => {
        axios
            .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=40`)
            .then((res) => {
                const inviteHeader = res.data[0]?.theheader.replace(
                    "username",
                    fnameuser + lnameuser
                );
                const inviteThetextName = res.data[0]?.thetext.replace(
                    "&lt;contact name&gt;, &lt;username&gt;",
                    `${invitedFname}${" "}${invitedLname}, ${fnameuser}${" "}${lnameuser}`
                );
                inviteapi(inviteHeader, inviteThetextName);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    function inviteapi(subject, body) {
        const urlParams =
            localStorage.getItem("userid") + "/" + contactDetails.contactid;
        const parsedURL = window.btoa(urlParams);
        const data = {
            nContactID: inviteContactId,
            emailaddress: mailUser,
            subject: subject,
            body: body,
            nUserID: nUserID,
            url: `https://keystomywishes.com/invite-signup/${parsedURL}/${contactDetails.email}`,
        };
        var token = localStorage.getItem("token");
        setIsLoading(true);
        axios
            .post(process.env.REACT_APP_SERVER + "contact/senduserinvitation", data, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                setIsLoading(false);
                handleinvitationModelClose();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            if (newAlignment === "left") {
                settile(0);
            } else if (newAlignment === "center") {
                settile(1);
            }

            const data = {
                nUserID: localStorage.getItem("userid"),
                nFlag: newAlignment === "left" ? 0 : 1,
            };
            var token = localStorage.getItem("token");
            axios
                .post(
                    process.env.REACT_APP_SERVER + "user/usercontactlayoutsave",
                    data,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const useStylesBootstrap = makeStyles((theme) => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const toolTipClasses = useStylesBootstrap();

    const handleLegendDailog = () => {
        setShowLegend(true);
    };

    useEffect(() => {

        axios.get(process.env.REACT_APP_SERVER + "getstates")
            .then((res) => {
                setisSaved(false);
            });
        getServicePlan()
    }, []);


    useEffect(() => {
        if(!isLoadedContactData && contactdata.length === 0){
            setShowLegend(true);
        }else {
            setShowLegend(false);
        }
    }, [contactdata,isLoadedContactData]);

    const getReadmeScreen = (response = []) => {
        if (response.length === 0) {
            axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=54`)
                .then((res) => {
                    setNoDataContent(res.data[0])
                })
                .catch((err) => { console.log(err)
                });
        }
    }

    function GetContactData() {
        setIsLoadedContactData(true)
        const data = { nUserID: localStorage.getItem("userid") };
        var token = localStorage.getItem("token");
        axios
            .post(process.env.REACT_APP_SERVER + "contact/listall", data, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data.status) {
                    setTokenExpired(true);
                } else {
                    setContactdata(res.data[0]);
                    getReadmeScreen(res.data[0]);
                    setIsLoadedContactData(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const [fnameuser, setFnameuser] = useState("");
    const [lnameuser, setLnameuser] = useState("");
    const [currentUser, setCurrentUser] = useState(false);
    useEffect(() => {
        GetContactData();
        var token = localStorage.getItem("token");
        axios
            .get(process.env.REACT_APP_SERVER + "profile/detail", {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                console.log(res.data[0])
                setCurrentUser(res.data[0])
                res &&
                res.data &&
                res.data.forEach((element) => {
                    setFnameuser(element.fname);
                    setLnameuser(element.lname);
                    settile(element.contactlayout);
                    setAlignment(element.contactlayout === 0 ? "left" : "center");
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        contactdata &&
        contactdata.forEach((els) => {
            if (els.isICE == 1) {
                $(`.tablecheckbox[name=${els.contactid}]`).prop("checked", true);
            }
        });
    });
    const submitContactClickBtn = (e) => {
        e.preventDefault();
        let phoneNumberDeA = value ? value.toString() : "";
        const data = {
            fName: fName,
            lName: lName,
            sAddress: sAddress,
            nUserID: nUserID,
            nisICE: nisICE,
            sCity: sCity,
            sEmail: sEmail,
            sNotes: sNotes,
            sPhoneNumber: phoneNumber(phoneNumberDeA),
            sharewith: sharewith,
            releaseto: releaseto,
            sZipCode: sZipCode,
            nContactID: nContactID,
            nStateID: nStateID,
            nOrderOfPriority: orderContact,
            strErrMsg: "",
        };

        const createApi = (finalValue) => {
            let items = {...data,sPhoneNumber:phoneNumber(finalValue)}
            axios
                .post(process.env.REACT_APP_SERVER + "contact/create", items, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => {
                    if (res.data.status) {
                        setTokenExpired(true);
                        setnewContectModel(false)
                    } else {
                        GetContactData();
                        setContactSavedModal(true);
                        setEdit(true);
                        setnewContectModel(false)
                        setShowModel(false)
                        setnContactID( res.data[0][0].nContactID);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        if (!edit) {
            var token = localStorage.getItem("token");
            if (!value && !sEmail) {
                handleShowphonemail();
            }
            else if (value) {
                createApi(value);
            }
            else {
                createApi();
            }
        } else {
            var token = localStorage.getItem("token");
            if (!value && !sEmail) {
                handleShowphonemail();
            } else {
                axios.post(process.env.REACT_APP_SERVER + "contact/update", data, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                    .then((res) => {
                        if (res.data.status) {
                            setTokenExpired(true);
                            setnewContectModel(false)
                        } else {
                            GetContactData();
                            // setContactSavedModal(true);
                            setnewContectModel(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
        // }
    };

    // checkbox
    function checkboxFun(e) {
        let target = e.target;
        if (target.checked) {
            setNisICE(1);
        } else {
            setNisICE(0);
        }
    }

    const [planNotSelected, setSelectPlan] = useState(false);
    const [newContectModel, setnewContectModel] = useState(false);
    const [isNewContact, setsetNewContact] = useState(true);

    const handleCloseNewmodel = () => {
        setnewContectModel(false);
        setEdit(false);
    };

    const newButtonClick = () => {
        setisSaved(false);
        setnContactID("");
        setOrderContact("");
        setNisICE("");
        setfName("");
        setlName("");
        setValue("+1");
        setsEmail("");
        setnewContectModel(true);
        setsetNewContact(true);
        if(contactdata.length === 0){

        }
    };

    const EditContactBtn = (contactData) => {
        setEdit(true);
        setsetNewContact(false);
        setnewContectModel(true);
        setnContactID(contactData.contactid);
        setfName(contactData.fname);
        setlName(contactData.lname);
        setValue(contactData.phonenumber);
        setsEmail(contactData.email);
        setNisICE(contactData.isICE);
        setOrderContact(contactData.orderofpriority);
        setcontactData(contactData);
    };
    const deleteButtonClicked = () => {
        const data = {
            nUserID: nUserID,
            nContactID: nContactID,
            strErrMsg: "",
        };
        var token = localStorage.getItem("token");
        axios
            .post(process.env.REACT_APP_SERVER + "contact/delete", data, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                setDeletMsg(res.data[0].Msg);
                if (res.data.status) {
                    setTokenExpired(true);
                } else {
                    handleCloses();
                    setShow(true);
                    if (res.data[0]["nReturn"] === 1){
                        GetContactData();
                    }
                }
            })
            .catch((err) => {
                handleShow();
                console.log(err);
            });
    };

    const DATA = [{
        dataField: "orderofpriority",
        style: (cell, row) => {
            if (row.isICE === 1) {
                if (row.contactid === nContactID) {
                    return {
                        color: "red",
                        backgroundColor: "lightgrey",
                        fontWeight: "600",
                    };
                } else {
                    return {
                        color: "red",
                        fontWeight: "600",
                    };
                }
            }
            if (row.contactid === nContactID) {
                return {
                    backgroundColor: "lightgrey",
                };
            } else {
                return {
                    paddingLeft: "5px",
                };
            }
        },
        text: "Order",
        formatter: (cellContent, row) => (
            <div className={"underline-text-all w-100"} onClick={() => setnewContectModel(true)}>
                <span className={"text-nowrap"}>{row.orderofpriority}</span>
            </div>
        ),
    },
        {
            dataField: "isICE",
            style: (cell, row) => {
                if (row.isICE === 1) {
                    if (row.contactid === nContactID) {
                        return {
                            color: "red",
                            backgroundColor: "lightgrey",
                            fontWeight: "600",
                        };
                    } else {
                        return {
                            color: "red",
                            fontWeight: "600",
                        };
                    }
                }
                if (row.contactid === nContactID) {
                    return {
                        backgroundColor: "lightgrey",
                    };
                }
            },
            text: "ICE",
            formatter: (cellContent, row) => (
                <div className="" onClick={() => setnewContectModel(true)}>
                    <input
                        type="checkbox"
                        name={row.contactid}
                        checked={row.isICE === 1}
                        className="tablecheckbox"
                        readOnly
                    />
                </div>
            ),
        },
        {
            dataField: "fname",
            text: "First Name",
            style: (cell, row) => {
                if (row.isICE === 1) {
                    if (row.contactid === nContactID) {
                        return {
                            color: "red",
                            backgroundColor: "lightgrey",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    } else {
                        return {
                            color: "red",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    }
                }
                if (row.contactid === nContactID) {
                    return {
                        backgroundColor: "lightgrey",
                        paddingLeft: "5px",
                    };
                } else {
                    return {
                        paddingLeft: "5px",
                    };
                }
            },
            formatter: (cellContent, row) => (
                <div
                    onClick={() => setnewContectModel(true)}
                    className={"underline-text    "}
                >
          <span
              className={`${row.isICE === 1 ? "text-danger" : "text-primary"}`}
          >
            {row.fname}
          </span>
                </div>
            ),
        },
        {
            dataField: "lname",
            text: "Last Name",
            style: (cell, row) => {
                if (row.isICE === 1) {
                    if (row.contactid === nContactID) {
                        return {
                            color: "red",
                            backgroundColor: "lightgrey",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    } else {
                        return {
                            color: "red",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    }
                }
                if (row.contactid === nContactID) {
                    return {
                        backgroundColor: "lightgrey",
                        paddingLeft: "5px",
                    };
                } else {
                    return {
                        paddingLeft: "5px",
                    };
                }
            },
            formatter: (cellContent, row) => (
                <div
                    onClick={() => setnewContectModel(true)}
                    className={"underline-text"}
                >
          <span
              className={`${row.isICE === 1 ? "text-danger" : "text-primary"}`}
          >
            {row.lname}
          </span>
                </div>
            ),
        },
        {
            dataField: "phonenumber",
            style: (cell, row) => {
                if (row.isICE === 1) {
                    if (row.contactid === nContactID) {
                        return {
                            color: "red",
                            backgroundColor: "lightgrey",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    } else {
                        return {
                            color: "red",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    }
                }
                if (row.contactid === nContactID) {
                    return {
                        backgroundColor: "lightgrey",
                        paddingLeft: "5px",
                    };
                } else {
                    return {
                        paddingLeft: "5px",
                    };
                }
            },
            text: "Phone",
            formatter: (cellContent, row) => (
                <div className={"underline-text-all"} onClick={() => setnewContectModel(true)}>
                    <span className={"text-nowrap"}>{row.phonenumber}</span>
                </div>
            ),
        },
        {
            dataField: "email",
            text: "Email",
            style: (cell, row) => {
                if (row.isICE === 1) {
                    if (row.contactid === nContactID) {
                        return {
                            color: "red",
                            backgroundColor: "lightgrey",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    } else {
                        return {
                            color: "red",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    }
                }
                if (row.contactid === nContactID) {
                    return {
                        backgroundColor: "lightgrey",
                        paddingLeft: "5px",
                    };
                } else {
                    return {
                        paddingLeft: "5px",
                    };
                }
            },
            formatter: (cellContent, row) => (
                <div className={"underline-text-all"} onClick={() => setnewContectModel(true)}>
                    <span className={"text-nowrap"}>{row.email}</span>
                </div>
            ),
        },
        {
            dataField: "sharedwith",
            style: (cell, row) => {
                if (row.isICE === 1) {
                    if (row.contactid === nContactID) {
                        return {
                            color: "red",
                            backgroundColor: "lightgrey",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    } else {
                        return {
                            color: "red",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    }
                }
                if (row.contactid === nContactID) {
                    return {
                        backgroundColor: "lightgrey",
                        paddingLeft: "5px",
                    };
                } else {
                    return {
                        paddingLeft: "5px",
                    };
                }
            },
            text: "Shared With",
            formatter: (cellContent, row) => (
                <div className="inviteBtn">
                    {row.sharedwith > 0 &&(
                        <Tooltip
                            arrow
                            classes={toolTipClasses}
                            placement="top-start"
                            title={`${row.sharedwith}`}
                        >
                            <div onClick={() => {
                                handleRowClick(row.contactid, 1)
                                setnewContectModel(false)
                            }}>
                                <img
                                    src={ic_users}
                                    alt="Add"
                                    className="w-5"
                                    style={{height: '30px',cursor:'pointer'}}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            dataField: "releaseto",
            style: (cell, row) => {
                if (row.isICE === 1) {
                    if (row.contactid === nContactID) {
                        return {
                            color: "red",
                            backgroundColor: "lightgrey",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    } else {
                        return {
                            color: "red",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    }
                }
                if (row.contactid === nContactID) {
                    return {
                        backgroundColor: "lightgrey",
                        paddingLeft: "5px",
                    };
                } else {
                    return {
                        paddingLeft: "5px",
                    };
                }
            },
            formatter: (cellContent, row) => {
                const namecontact = row.fname + " " + row.lname;
                return (
                    <>
                        {row.releaseto > 0 && (
                            <div
                                onClick={(e) => {
                                    handleRowClick(row.contactid, 2, namecontact)
                                    setnewContectModel(false)
                                    e.stopPropagation()
                                }}
                                className={"text-center"}
                            >
                                <img
                                    src={ReleaseImg}
                                    alt="Add"
                                    className="w-5"
                                    style={{height: '30px', cursor: 'pointer'}}
                                />
                            </div>
                        )
                        }
                    </>
                );
            },
            text: "Release To",
        },
        {
            dataField: "send_invite",
            text: "Share Link",
            style: (cell, row) => {
                if (row.isICE === 1) {
                    if (row.contactid === nContactID) {
                        return {
                            color: "red",
                            backgroundColor: "lightgrey",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    } else {
                        return {
                            color: "red",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    }
                }
                if (row.contactid === nContactID) {
                    return {
                        backgroundColor: "lightgrey",
                        paddingLeft: "5px",
                    };
                } else {
                    return {
                        paddingLeft: "5px",
                    };
                }
            },
            formatter: (cellContent, row) => (
                <div className="inviteBtn">
                    {(row.sharedwith > 0) && (
                        <img
                            onClick={(e) => {
                                handleShowShareLinkModal(row)
                                setnewContectModel(false)
                                e.stopPropagation()
                            }}
                            src={shareImg}
                            alt={"shareIcon"}
                            style={{ height: "25px" }}
                        />
                    )}
                </div>
            ),
        },
        {
            dataField: "hasAccess",
            text: "Invitation Status",
            style: (cell, row) => {
                if (row.isICE === 1) {
                    if (row.contactid === nContactID) {
                        return {
                            color: "red",
                            backgroundColor: "lightgrey",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    } else {
                        return {
                            color: "red",
                            fontWeight: "600",
                            paddingLeft: "5px",
                        };
                    }
                }
                if (row.contactid === nContactID) {
                    return {
                        backgroundColor: "lightgrey",
                        paddingLeft: "5px",
                    };
                } else {
                    return {
                        paddingLeft: "5px",
                    };
                }
            },
            formatter: (cellContent, row) => (
                <div className="inviteBtn">
                    {row["hasAccess"] && (
                        <Box>
                            <Typography
                                className="link"
                                onClick={(e)=> {
                                    e.stopPropagation()
                                    toggleApprovalModal();
                                }}
                            >{row["hasAccess"]}</Typography>
                        </Box>
                    )}
                </div>
            ),
        },
    ];

    const rowEvents = {
        onClick: (e, row) => {
            console.log(e)
            GetContactData();
            const cdata = contactdata.find((ele) => ele.contactid == row.contactid);
            setEdit(true);
            if(!currentUser?.newuser && currentUser?.serviceplanrequired){
                setnewContectModel(false)
            }else {
                setnewContectModel(true)
            }

            setnContactID(cdata.contactid);
            setfName(cdata.fname);
            setlName(cdata.lname);
            setValue(cdata.phonenumber);
            setsharewith(cdata.sharewith);
            setreleaseto(cdata.releaseto);
            setsEmail(cdata.email);
            setsAddress(cdata.address);
            setsCity(cdata.city);
            setsNotes(cdata.notes);
            setOrderContact(cdata.orderofpriority);
            setnStateID(cdata.stateid);
            setsZipCode(cdata.zipcode);
            if (cdata.isICE === 1) {
                $(`.Checked-box`).prop("checked", true);
                setNisICE(1);
            } else {
                $(`.Checked-box`).prop("checked", false);
                setNisICE(0);
            }
            setDeletebtnDisable(true);
        },
    };

    const [resData, setResData] = useState();

    const [show, setShow] = useState(false);
    const [showMessageConfirmation , setShowMessageConfirmation] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [shows, setShows] = useState(false);
    const handleCloses = () => {
        setShows(false);
    };
    const handleShows = (contactid) => {
        setnContactID(contactid);
        setShows(true);
    };

    const hadleshowdelete = () => {
        setShows(true);
    };

    const [showphonemail, setShowphonemail] = useState(false);
    const handleClosephonemail = () => setShowphonemail(false);
    const handleShowphonemail = () => setShowphonemail(true);

    const [showphone, setShowphone] = useState(false);
    const handleClosephone = () => setShowphone(false);
    const handleShowphone = () => setShowphone(true);

    const [sendTextEmailModalShow, setSendTextEmailModalShow] =
        React.useState(false);
    const [sendShow, setSendShow] = React.useState(false);

    const handleDeleteRecord = () => {
        handleCloses();
        deleteButtonClicked();
    };

    const handleClosePlan = () => {
        setSelectPlan(false);
    };

    const sendTextEmail = () => {
        setSendTextEmailModalShow(false);
    };

    const closeConfirmation= () => {
        setShowMessageConfirmation(false)
    }
    const closeSendTextEmailModal = () => {
        setSendTextEmailModalShow(false);
    };

    const sendTextEmaildata = () => {
        setSendShow(false);
    };

    const closeModal = () => {
        setSendShow(false);
    };
    const [showModel, setShowModel] = useState(false);

    const handleCloseModel = () => {
        setShowModel(false);
    };

    const [sharableURL, setSharableURL] = React.useState("");
    const [isOpenShareLinkInfoModal, setShareLinkInfoModal] = React.useState(false);
    const [currentContact, setCurrentContact] = React.useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false)
    const [sendEmail, setSendEmail] = React.useState(false);
    const [sendMessage, setSendMessage] = React.useState(false);
    const [invitationSending, setInvitationSending] = React.useState(false)
    const [openRequiredModel, setOpenRequiredModel] = React.useState(false);

    let inviteData = {
        fname: currentContact.fname,
        lname: currentContact.lname,
        contactid: currentContact.contactid,
        email: currentContact.email,
        phonenumber: currentContact.phonenumber,
    };

    const closeConfirmationModal = () => {
        setOpenConfirmationModal(false);
        setSendEmail(false);
        setSendMessage(false);
    }

    const handleserviceplan = () => {
        setOpenRequiredModel(!openRequiredModel);
    }
    const handleShare = () => {
        const token = localStorage.getItem("token");
        const params = {
            params :{
                nUserID: localStorage.getItem("userid"),
                nContactID: currentContact.contactid
            },
            headers: {Authorization: `Bearer ${token}`}
        }

        const getReadMe = (response) => {
            return axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=60`)
                .then((res) => {
                    return res.data[0].thetext.replace("&lt;username&gt;",response.sSendersName).replace("&lt;contactname&gt;",inviteData?.fname + " " + inviteData?.lname);
                }).catch((err) => {
                    console.log(err)
                })
        }
        setInvitationSending(true)
        axios.post(process.env.REACT_APP_SERVER + 'inventory/shareRequestOwnerSave',{},params)
            .then((response)=> {
                getReadMe(response.data[0]).then((thetext)=>{
                    axios.post(process.env.REACT_APP_SERVER + 'usersendsms', {
                        sphonenumber: (inviteData.phonenumber && inviteData?.phonenumber) ? inviteData?.phonenumber : '',
                        smessage: thetext,
                        smessageSMS: wish.removeHtml(thetext),
                        ssubject: 'keys to my wishes Verification Code',
                        swhoto: inviteData?.fname,
                        semailaddress: inviteData?.email ? inviteData?.email : '',
                        nUserID: localStorage.getItem("userid"),
                        nContactID: nContactID,
                        bTextSent: sendMessage,
                        bEmailSent: sendEmail,
                        sRequestCode:response.data[0].sRequestCode,
                        otpParam:2
                    }).then((res) => {
                        GetContactData();
                        closeConfirmationModal();
                        setShowMessageConfirmation(true);
                        setInvitationSending(false)
                    }).catch((err)=>{
                        console.log(err)
                        setInvitationSending(false)
                    })
                })
            }).catch((err)=>{
            console.log(err)
        })
    }
    const handleShowShareLinkModal = (e) => {
        console.log(e)
        setCurrentContact(e)
        setOpenConfirmationModal(true)
    };

    const [getUserName, setGetUserName] = useState("");

    const handleRowClick = (contactId = "", nWhichOne = "", fullName) => {
        setGetUserName(fullName);
        setShowModel(true);
        setResData(null);
        setNWhichOne(nWhichOne);
        const token = localStorage.getItem("token");
        const server = process.env.REACT_APP_SERVER;
        axios
            .get(
                server +
                `inventory/getsharedwithdistributedto?nUserID=${localStorage.getItem(
                    "userid"
                )}&nContactID=${contactId}&nWhichOne=${nWhichOne}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then((res) => {
                setResData(res.data);
            });
    };

    const handleShowModal = (contactId,screen = 1) => {
        setShowModel(true);
        setNWhichOne(1);
        axios
            .get(process.env.REACT_APP_SERVER +
                `inventory/getsharedwithdistributedto?nUserID=${localStorage.getItem("userid")}&nContactID=${contactId}&nWhichOne=${screen}`,
                {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
            ).then((res) => {
            setResData(res.data);
        }).catch((err)=>{
            console.log(err)
        })
    };

    return (
        <>
            <Modal show={openRequiredModel} onHide={handleserviceplan}>
                <Modal.Header
                    closeButton
                    style={{ backgroundColor: "#0069d9", color: "white" }}
                >
                    <Modal.Title>Service Plan Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This functionality is not available until you sign up with a Service Plan.
                    Please click on the Purchase Service Plan Button on the Wishes page
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btngenie" onClick={handleserviceplan}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={openConfirmationModal}
                onHide={closeConfirmationModal}
            >
                <Modal.Header
                    closeButton
                    className={"modelHeader"}
                >
                    <Modal.Title>Send OTP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"ml-3 dataMain"}>
                        <div className="d-flex align-items-center">
                            <Checkbox
                                checked={sendEmail}
                                color="primary"
                                inputProps={{
                                    "aria-label": "secondary checkbox",
                                }}
                                // value={inviteData?.email}
                                onChange={(e) => setSendEmail(!sendEmail)}
                                disabled={!inviteData?.email}
                            />
                            <span>Send Email ( {inviteData.email} )</span>
                        </div>
                        <div className="d-flex align-items-center">
                            {
                                <>
                                    <Checkbox
                                        checked={sendMessage}
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "secondary checkbox",
                                        }}
                                        // value={inviteData?.phonenumber}
                                        onChange={(e) => setSendMessage(!sendMessage)}
                                        disabled={!inviteData?.phonenumber}
                                    />
                                    <span>Send SMS ( {inviteData.phonenumber} )</span>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btngenie"
                        style={{height: "40px", width: "60px"}}
                        onClick={ServicePlan ? handleShare : handleserviceplan}
                        disabled={!sendMessage && !sendEmail}
                    >
                        {invitationSending
                            ? <CircularProgress/>
                            : <span>SEND</span>}
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal className="Welcome_modals" show={showMessageConfirmation} onHide={closeConfirmation}>
                <Modal.Header
                    style={{backgroundColor: "#007bff", color: "white", border: "none"}}
                >
                    <Modal.Title className="d-flex justify-content-center position-relative">
                        <div className="icon-box">
                            <i className="material-icons">&#xE876;</i>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="mb-4">
                        <div>Your message was successfully sent!.</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={closeConfirmation}
                        style={{
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                        }}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={InvitaionModel} onHide={handleinvitationModel} className={'Send-Invitation-Confirmation-Modal'}>
                <Modal.Header style={{ backgroundColor: "#007bff", color: "white" }}>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to send an Invitation?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleinvitationModel}>
                        No
                    </Button>
                    <Button style={{ backgroundColor: "#0069d9" }} className="invite_btn">
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <span onClick={sentInvitaionemail}>Yes</span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
            {TokenExpired && <SessionExpired />}
            <Modal show={planNotSelected} onHide={handleClosePlan}>
                <Modal.Header closeButton className={"modelHeader"}>
                    <Modal.Title>Please Select Your Plan!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please Select Your Plan!</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        style={{ backgroundColor: "#0069d9" }}
                        onClick={handleClosePlan}
                    >
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={planNotSelected} onHide={handleClosePlan}>
                <Modal.Header closeButton className={"modelHeader"}>
                    <Modal.Title>Please Select Your Plan!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please Select Your Plan!</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        style={{ backgroundColor: "#0069d9" }}
                        onClick={handleClosePlan}
                    >
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
            <section className="contact-section">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header
                        closeButton
                        style={{ backgroundColor: "#0069d9", color: "white" }}
                    >
                        Confirmation
                    </Modal.Header>
                    <Modal.Body>{deletMsg.replace("Items", "Wishes")}</Modal.Body>
                    <Modal.Footer>
                        <Button
                            style={{ backgroundColor: "#0069d9", color: "white" }}
                            onClick={handleClose}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={shows} onHide={handleCloses}>
                    <Modal.Header style={{ backgroundColor: "#0069d9", color: "white" }}>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete the selected record?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloses}>
                            No
                        </Button>
                        <Button
                            style={{ backgroundColor: "#0069d9" }}
                            onClick={handleDeleteRecord}
                        >
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showphonemail} onHide={handleClosephonemail}>
                    <Modal.Header style={{ backgroundColor: "#0069d9", color: "white" }}>
                        <Modal.Title>Email & Phone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        You must enter either an Email Address or a Phone Number!.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            style={{ backgroundColor: "#0069d9", color: "white" }}
                            onClick={handleClosephonemail}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showphone} onHide={handleClosephone}>
                    <Modal.Header style={{ backgroundColor: "#0069d9", color: "white" }}>
                        <Modal.Title> Phone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body> Enter Valid Phone Number!</Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={handleClosephone}
                            style={{ backgroundColor: "#0069d9", color: "white" }}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Header title="CLIENTS" />
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn hoy-to-link-btn">
                            <img src={Back} alt="Back" className="w-5" /> Back
                        </Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="btnall">
                        <Logout />
                    </Col>
                </Row>
                <Col md={7}>
                    <div
                        className={`d-flex justify-content-between`}
                        style={{ alignItems: "flex-end" }}
                    >
                        <img
                            src={genie_img}
                            alt="genie_img"
                            className="genie"
                            onClick={handleLegendDailog}
                        />
                    </div>
                </Col>
                <Row className="bg-white p-3 ">
                    <Col md={12} className={"mx-auto"}>
                        <div className="contact-table-sec">
                            <div className="btnContainer flex-row flex-nowrap button_contact d-flex justify-content-between align-items-center">
                                <div className="add_contact_content">
                                    <Button
                                        variant="success"
                                        onClick={newButtonClick}
                                        className="btn mr-2 text-nowrap"
                                        disabled={(!currentUser?.newuser && currentUser?.serviceplanrequired)}
                                    >
                                        + Add Contact
                                    </Button>
                                    {tile ? (
                                        <Button
                                            variant="danger"
                                            onClick={() => {
                                                hadleshowdelete();
                                            }}
                                            disabled={!deletebtnDisable || nContactID === ""}
                                            className={"delete-contact-table-row"}
                                        >
                                            Delete
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="float-right mb-2 d-flex">
                                    <div className="float-right mb-2 d-flex styles_icon">
                                        <ToggleButtonGroup
                                            value={alignment}
                                            exclusive
                                            onChange={handleAlignment}
                                            aria-label="text alignment"
                                        >
                                            <ToggleButton value="left" aria-label="left aligned">
                                                <img src={Menutiles} className="tile-img" />
                                            </ToggleButton>
                                            <ToggleButton value="center" aria-label="centered">
                                                <img src={Menutiles2} className="tile-img" />
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                            </div>
                            {!isLoadedContactData && contactdata.length === 0 ? (
                                <Row className="align-items-center justify-content-center">
                                    <Col
                                        md={7}
                                        className="position-relative d-flex justify-content-center flex-wrap"
                                    >
                                        <div className={`genie_parent GenieMen`}>
                                            <img src={GenieMen} alt="GenieMen" className="genie" />
                                        </div>
                                        <div
                                            className="contact_description"
                                            dangerouslySetInnerHTML={{ __html: noDataContent.thetext }}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <div className="contact_data_table">
                                    {tile === 1 ? (
                                        <BootstrapTable
                                            keyField="id"
                                            data={contactdata}
                                            columns={DATA}
                                            rowEvents={rowEvents}
                                        />
                                    ) : (
                                        tile === 0 && (
                                            <Grid container spacing={2} className={"cardContainer "}>
                                                {contactdata.map((e, index) => {
                                                    return (
                                                        <Grid item className="card_grid" key={index}>
                                                            <Card className="contact_card">
                                                                <CardContent
                                                                    className={`${
                                                                        e.isICE
                                                                            ? "card_header_danger"
                                                                            : "card_header"
                                                                    }`}
                                                                    style={{
                                                                        display: "flex",
                                                                        padding: "2px",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        backgroundColor: "#0069D9",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        className="edit_btn_contact"
                                                                        onClick={() => EditContactBtn(e)}
                                                                        disabled={(!currentUser?.newuser && currentUser?.serviceplanrequired)}
                                                                    >
                                                                        <b>View/Edit</b>
                                                                    </Button>
                                                                    <span
                                                                        className="delete_btn_contact"
                                                                        onClick={() => handleShows(e.contactid)}
                                                                    >
                                    <b>Delete</b>
                                  </span>
                                                                </CardContent>
                                                                <CardContent className="card_body">
                                                                    <div className={"d-flex align-items-center"}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={e.isICE === 1}
                                                                            className="Checked-box mr-2 emer_check_box"
                                                                        />
                                                                        <span>
                                      <b style={{fontSize:"18px"}}>Emergency Contact</b>
                                    </span>
                                                                    </div>

                                                                    <div className="user_name mt-3 white-spaces-pre">
                                                                        <b>
                                                                            Name: {e.fname} {e.lname}
                                                                        </b>
                                                                    </div>
                                                                    {e.phonenumber && (
                                                                        <div className="user_phone_number">
                                                                            <b>Phone: {e.phonenumber}</b>
                                                                        </div>
                                                                    )}
                                                                    {e.email && (
                                                                        <div className="user_email">
                                                                            <b className="mr-1">Email:</b>
                                                                            <span className="email_address">{e.email}</span>
                                                                        </div>
                                                                    )}
                                                                </CardContent>
                                                                <Box
                                                                    display="flex"
                                                                    sx={{
                                                                        alignItems:"center",
                                                                        borderTop:"1px solid #bac2af",
                                                                        height:"48px"
                                                                    }}
                                                                    px={2} py={1}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            gap:"10px",
                                                                            display:"flex",
                                                                            alignItems:"center"
                                                                        }}
                                                                    >
                                                                        <div className={"diamond"}/>
                                                                        <div style={{fontSize:"15px"}}>Activation Required Agent:Amber A.</div>
                                                                    </Box>
                                                                </Box>
                                                            </Card>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </section>
            {sendTextEmailModalShow ? (
                <SendTextEmailModal
                    sendTextEmailModalShow={sendTextEmailModalShow}
                    sendTextEmail={sendTextEmail}
                    closeSendTextEmailModal={closeSendTextEmailModal}
                    contactdata={contactdata}
                />
            ) : null}
            <NewContact
                sendShow={sendShow}
                contactDetails={contactDetails}
                handleinvitationModel={handleinvitationModel}
                GetContactData={GetContactData}
                setSendShow={setSendShow}
                newContectModel={newContectModel}
                handleCloseNewmodel={handleCloseNewmodel}
                submitContactClickBtn={submitContactClickBtn}
                checkboxFun={checkboxFun}
                orderContact={orderContact}
                setOrderContact={setOrderContact}
                setfName={setfName}
                fName={fName}
                lName={lName}
                setlName={setlName}
                sEmail={sEmail}
                setsEmail={setsEmail}
                isSaved={isSaved}
                setValue={setValue}
                value={value}
                nisICE={nisICE}
                isNewContact={isNewContact}
                contactId={nContactID}
                nUserID={nUserID}
                sPhoneNumber={sPhoneNumber}
                setnewContectModel={setnewContectModel}
                contactdata={contactdata}
            />

            <Modal
                show={showModel}
                onHide={handleCloseModel}
                size={"lg"}
                centered
                className={"contactGenieModalContainer"}
            >
                <Modal.Header closeButton className={classes.sharedWishesModalHeader}>
                    <Modal.Title>
                        {nWhichOne === 1 ? (
                            <h5>Wishes that will be Shared With</h5>
                        ) : (
                            <h5>Wishes that will be Released To</h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {resData?.length < 1 ? (
                        <p className={"text-center"}>No Data Found!</p>
                    ) : (
                        <>
                            <div className="distributed-Instructions">
                                {nWhichOne === 1 ? (
                                    <>
                    <span>
                      The following is a List of Wishes that will be Shared
                      With: {getUserName}
                    </span>
                                        <p>
                                            This link will allow your contact: {getUserName} to view
                                            your Wishes that you are sharing.{" "}
                                        </p>
                                    </>
                                ) : (
                                    <p>
                                        The following is a List of Wishes that will be Released to:{" "}
                                        {getUserName}
                                    </p>
                                )}
                            </div>
                            {resData?.map((e, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            style={{ marginTop: "10px", }}
                                            className="underline-text-all"
                                            dangerouslySetInnerHTML={{
                                                __html: e?.itemname.replaceAll("\n", "<br/>"),
                                            }}
                                        />
                                        {resData?.length > 1 && <hr style={{borderColor:"rgb(0 0 0 / 45%)"}}/>}
                                    </div>
                                );
                            })}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseModel}
                        style={{ backgroundColor: "#0069d9"}}
                    >
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
            <SharedAccessApprovalModal
                isOpen={isOpenSharedModal}
                toggleApprovalModal={toggleApprovalModal}
            />
            <DraggebleModal
                screen={1}
                isOpen={showLegend}
                setOpenModal={setShowLegend}
            />
            <HandleConfirmation
                isOpen={isContactSaved}
                handleClose={()=> setContactSavedModal(false)}
                onConfirm={()=> setContactSavedModal(false)}
                color="secondary"
                title="Contact Information"
                question="Contact information has been successfully saved!"
                yesText="Okay"
                noText=""
            />
            <HandleConfirmation
                isOpen={isOpenShareLinkInfoModal}
                handleClose={() => setShareLinkInfoModal(false)}
                onConfirm={()=>setShareLinkInfoModal(false)}
                question="Sorry, you don't have any wishes shared with this contact. <br/>You must assign the contact to at least one Wish in order to use this Shared Link button."
                yesText="Okay"
                noText=""
            />
            {sendShow ? (
                <Invitemodel
                    sendShow={sendShow}
                    setValue={setValue}
                    sendTextEmaildata={sendTextEmaildata}
                    GetContactData={GetContactData}
                    closeModal={closeModal}
                    sendInviteData={inviteData}
                    nUserID={nUserID}
                    nContactID={currentContact.contactid}
                    sharableURL={sharableURL}
                    currentUser={currentUser}
                    ServicePlan={ServicePlan}
                />
            ) : null}
        </>
    );
};
export default Contacts;
